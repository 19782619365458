import * as React from 'react';
import { fetchRatingsOnce } from '../../../actions/Ratings';
import { ImpactRunTimes, LocationData, RatingsDataWithLocationInfo } from '../../../types';
import TimelineChart from './TimelineChart';
import { Timeframe } from "./data";
import { CircularProgress } from "@mui/material";

interface Props {
    token: string;
    location: LocationData;
    runTimes: ImpactRunTimes;
    timeframe: Timeframe;
    height: number;
}

export const ImpactTimelineComponent = (props: Props) => {
    const { token, location, runTimes, timeframe } = props;
    const [ratingsData, setRatingsData] = React.useState<RatingsDataWithLocationInfo | undefined>(undefined);

    const fetchMemo = React.useMemo(
        () => {
            return () => {
                return fetchRatingsOnce(token, new AbortController(), location.latitude, location.longitude, runTimes);
            };
        },
        [token, location?.id, runTimes?.road] //location and runtime object may change so check location id and one of the runtimes for changes
    );

    React.useEffect(() => {
        // clear initial state when component is reused
        setRatingsData(undefined);

        // fetch new data
        fetchMemo().then(value => {
            setRatingsData(value);
        });
    }, [fetchMemo]);

    if (ratingsData === undefined) {
        return (
            <div style={{ backgroundColor: 'rgb(44, 52, 59)' }}>
                <div style={{ height: props.height, backgroundColor: 'rgba(144, 202, 249, 0.16)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress />
                </div>
            </div>
        );
    }
    return (
        <div style={{ backgroundColor: 'rgb(44, 52, 59)' }}>
            <div style={{ backgroundColor: 'rgba(144, 202, 249, 0.16)' }}>
                <TimelineChart
                    id={`${location.id}-${'timeline'}`}
                    ratingsData={ratingsData}
                    timeframe={timeframe}
                    impactSummariesUpdatedAt={location.impactSummariesUpdatedAt}
                    height={props.height}
                />
            </div>
        </div>
    );
};
