import { relevantRatingKeys } from '../actions/Ratings';

export type ImpactKey = 'road' | 'flood' | 'outdoor' | 'power' | 'disruption' | 'life_property' | 'wildfire_spread' | 'wildfire_conditions';

export type WeatherKey = 'radar' | 'temperature' | 'rain_accumulation' | 'snow_accumulation' | 'wind_speed' | 'wind_gust';

export type RatingKey = ImpactKey | WeatherKey;

// first one here always needs to be one available to anyone with ratings access
export const ALL_RATING_KEYS = relevantRatingKeys(true, true);

// replace '_' with ' & ' and capitalize each word
export const formatRatingKey = (ratingKey: string) => {
    if (!isImpactKey(ratingKey) && !isWeatherKey(ratingKey)) {
        return ratingKey;
    }
    return ratingKey.replaceAll('e_p', 'e & p').replaceAll('_', ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};

const impactSet = new Set(['road', 'flood', 'outdoor', 'power', 'disruption', 'life_property', 'wildfire_spread', 'wildfire_conditions']);
export const isImpactKey = (keyInput: string | undefined): boolean => {
    if (keyInput !== undefined)
        return impactSet.has(keyInput);
    return false;
};
const weatherSet = new Set(['temperature', 'rain_accumulation', 'snow_accumulation', 'wind_speed', 'wind_gust', 'lightning_probability']);
export const isWeatherKey = (keyInput: string | undefined): boolean => {
    if (keyInput !== undefined)
        return weatherSet.has(keyInput);
    return false;
};
