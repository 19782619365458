import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import * as React from 'react';
import { cardBackgroundColor } from "../../constants";
import { CircularProgress, Tooltip } from '@mui/material';

export function findIndexWithDefaultValue<T>(array: T[], transform: (item: T) => boolean, defaultValue: number | undefined = -1) {
    const returnValue = array.findIndex(transform);
    if (returnValue === -1) {
        return defaultValue;
    }
    return returnValue;
}

export interface MapControlsProps {
    paused: boolean;
    speedIncrement: number;
    times: Date[];
    displayedTime: Date | undefined;
    // TODO: make nonnull
    loading?: boolean;
    horizontalLayout?: boolean;

    onPause: () => void;
    onResume: () => void;
    onSkipToFirst: () => void;
    onRewind: () => void;
    onFastForward: () => void;
    onSkipToLast: () => void;
    onCycleSpeedIncrement: () => void;
    setSelectedDate?: (date: Date) => void;
}
export const MapControlsComponent = (props: MapControlsProps) => {
    const [showLoadingSpinner, setShowLoadingSpinner] = React.useState(false);
    const [loadingDebounceTimer, setLoadingDebounceTimer] = React.useState<NodeJS.Timeout | undefined>(undefined);

    React.useEffect(() => {
        if (!props.loading) {
            clearTimeout(loadingDebounceTimer);
            setShowLoadingSpinner(false);
        } else {
            setLoadingDebounceTimer(setTimeout(() => {
                setShowLoadingSpinner(true);
            }, 300));
        }
    }, [props.loading]);

    let displayedString = "";

    displayedString = props.displayedTime ? props.displayedTime.toLocaleDateString('en-US', {
        weekday: 'short',
        month: 'short',
        day: 'numeric'
    }) + "\u00A0\u00A0\u00A0\u00A0" + props.displayedTime.toLocaleTimeString('en-US', {
        hour: 'numeric',
        hourCycle: 'h12',
        minute: 'numeric',
        timeZoneName: 'short'
    }) : '';

    let pauseResumeButton;
    if (props.paused) {
        pauseResumeButton = (
            <button className={'resume-button time-control-button'} disabled={props.displayedTime === undefined} onClick={() => props.onResume()}>
                <img alt={'play'} src={'/images/play.svg'} />
            </button>
        );
    } else {
        pauseResumeButton = (
            <button className={'pause-button time-control-button'} disabled={props.displayedTime === undefined} onClick={() => props.onPause()}>
                <img alt={'pause'} src={'/images/pause.svg'} />
            </button>
        );
    }

    const matchingTimeIndex = findIndexWithDefaultValue(props.times, x => props.displayedTime?.getTime() === x.getTime(), 0);

    const mapButtons = (
        <div className={'map-buttons'}>
            <button className={'skip-to-first-button time-control-button'} disabled={props.displayedTime === undefined} onClick={() => props.onSkipToFirst()}>
                <img alt={'skip-to-first'} src={'/images/skip-to-first.svg'} />
            </button>
            <button className={'rewind-button time-control-button'} disabled={props.displayedTime === undefined} onClick={() => props.onRewind()}>
                <img alt={'rewind'} src={'/images/rewind.svg'} />
            </button>
            {pauseResumeButton}
            <button className={'fast-forward-button time-control-button'} disabled={props.displayedTime === undefined} onClick={() => props.onFastForward()}>
                <img alt={'fast-forward'} src={'/images/fast-forward.svg'} />
            </button>
            <button className={'skip-to-last-button time-control-button'} disabled={props.displayedTime === undefined} onClick={() => props.onSkipToLast()}>
                <img alt={'skip-to-last'} src={'/images/skip-to-last.svg'} />
            </button>
        </div>
    );

    const timeLabel = (
        <Tooltip placement='bottom' title={props.displayedTime?.toISOString() ?? ""} PopperProps={{
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, -15],
                    },
                },
            ],
        }}>
            <div className={'map-timestamp'}>
                <span>{displayedString}</span>
            </div>
        </Tooltip>
    );

    const speedIncrement = (
        <button className={'cycle-speed-increment-button time-control-button'} onClick={() => props.onCycleSpeedIncrement()}>
            <span>{props.speedIncrement}x</span>
        </button>
    );

    const timeSlider = (
        <div className={'map-timing'}>
            <div className={'slider-container'}>
                <Slider
                    className={'slider'}
                    min={0}
                    max={props.times.length - 1}
                    step={1}
                    defaultValue={0}
                    value={matchingTimeIndex}
                    onChange={(value: number) => props.setSelectedDate?.(props.times[value])}
                    trackStyle={{ backgroundColor: "rgba(4, 160, 253, 0.8)", height: 5 }}
                    railStyle={{ backgroundColor: "rgba(4, 160, 253, 0.3)", height: 5 }}
                    handleStyle={{ borderColor: "transparent", borderWidth: 1, backgroundColor: "rgb(0, 147, 255)", width: 9, height: 9, marginLeft: -4, marginTop: -2 }}
                />
            </div>
            {props.horizontalLayout === undefined && speedIncrement}
        </div>
    );

    const mapControls = (
        <div className={'MapControlsComponent'} style={{ backgroundColor: cardBackgroundColor }}>
            {showLoadingSpinner && <CircularProgress style={{
                width: '16px',
                height: '16px',
                position: 'absolute',
                left: '8px',
                top: '10px',
            }} />}
            {timeLabel}
            {mapButtons}
            {timeSlider}
        </div>
    );

    const horizontalMapControls = (
        <div className={'HorizontalMapControlsComponent'} style={{ backgroundColor: cardBackgroundColor }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingRight: 6, marginLeft: -4 }}>
                {showLoadingSpinner ? <CircularProgress style={{ width: '16px', height: '16px', }} /> : <div style={{ width: '16px', height: '16px' }} />}
            </div>
            {mapButtons}
            <div style={{ margin: '0px 10px' }}>
                {timeSlider}
                {timeLabel}
            </div>
            {speedIncrement}
        </div>
    );

    return props.horizontalLayout ? horizontalMapControls : mapControls;
};