import { Box, Typography, Select, MenuItem, TextField, Chip, Checkbox, ListItemText, Button } from "@mui/material";
import React from "react";
import { AssetFilter, areAssetFiltersEqual, isAssetFilterValid, impactLevelOperators, numericOperators, arrayOperators, assetFilterVariables, labelForValueOption, labelForOperator, labelForVariable } from "src/types/AssetFilter";

interface SingleFilterSelectorProps {
    filter: AssetFilter,
    assetAlerts: string[];
    assetRisks: string[];
    onFilterChanged: (filter: AssetFilter) => void
}

export const SingleFilterSelector = (props: SingleFilterSelectorProps) => {
    const [variable, setVariable] = React.useState(props.filter.variable);
    const variableRef = React.useRef(props.filter.variable);
    const [operator, setOperator] = React.useState(props.filter.operator);
    const [value, setValue] = React.useState(props.filter.value);

    const [filter, setFilter] = React.useState(props.filter);

    const [userEnteredValue, setUserEnteredValue] = React.useState('');

    // when user changes fields, update filter state variable
    React.useEffect(() => {
        if (!areAssetFiltersEqual(filter, { variable, operator, value })) {
            setFilter({ variable, operator, value });
        }
    }, [variable, operator, value]);

    // when filter state variable becomes a complete filter, pass it to the parent component
    React.useEffect(() => {
        if (isAssetFilterValid(filter)) {
            props.onFilterChanged(filter);
        }
    }, [filter]);

    const [availableOperators, setAvailableOperators] = React.useState<string[]>([]);
    const [availableValues, setAvailableValues] = React.useState<string[] | 'any-numeric'>([]);
    const [operatorDisabled, setOperatorDisabled] = React.useState(false);
    const [valueDisabled, setValueDisabled] = React.useState(false);
    const [isMultiSelect, setMultiSelect] = React.useState(false);

    // reset operator & value when variable changes, update options for them as well
    React.useEffect(() => {
        if (variableRef.current !== variable) {
            // variable changed, so clear operator and values
            setOperator(null);
            setValue(null);
            setMultiSelect(false);
        }

        // set operators and values based on variable
        switch (variable) {
            case 'impact_level':
                setAvailableOperators(impactLevelOperators);
                setOperator(impactLevelOperators[0]);
                setAvailableValues(['none', 'low', 'moderate', 'high']);
                break;
            case 'road_risk':
                setAvailableOperators(numericOperators);
                setOperator(numericOperators[0]);
                setAvailableValues([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(n => n.toString()));
                break;
            case 'max_wind_gust':
                setAvailableOperators(numericOperators);
                setOperator(numericOperators[0]);
                setAvailableValues('any-numeric');
                break;
            case 'risks':
                setAvailableOperators(arrayOperators);
                setOperator(arrayOperators[0]);
                setAvailableValues(props.assetRisks);
                setMultiSelect(true);
                setValue([]);
                break;
            case 'gov_alert_issued':
                setAvailableOperators(arrayOperators);
                setOperator(arrayOperators[0]);
                setAvailableValues(props.assetAlerts);
                setMultiSelect(true);
                setValue([]);
                break;
            default:
                setAvailableOperators([]);
                setAvailableValues([]);
                break;
        }
    }, [variable]);

    // disable operator/value fields if we don't have a valid variable selected
    React.useEffect(() => {
        setOperatorDisabled(availableOperators.length === 0);
        setValueDisabled(availableValues === null || availableValues.length === 0 || operator === 'any');
    }, [availableOperators, availableValues, operator]);

    // when the user enters a valid numeric value, convert it to a number and set it
    React.useEffect(() => {
        if (availableValues === 'any-numeric') {
            if (userEnteredValue === '') {
                setValue(null);
            } else if (!isNaN(parseFloat(userEnteredValue))) {
                setValue(parseFloat(userEnteredValue));
            }
        }
    }, [userEnteredValue]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            {/* Variable Field */}
            <Typography fontWeight="bold" variant="caption">Variable</Typography>
            <Select size="small" value={variable} onChange={e => setVariable(e.target.value as string)} sx={{ fontSize: '0.8rem' }}>
                {assetFilterVariables.map(v => (
                    <MenuItem key={v} value={v}>
                        <Typography variant="caption">
                            {labelForVariable(v)}
                        </Typography>
                    </MenuItem>
                ))}
            </Select>

            {/* Operator Field */}
            {!operatorDisabled && <>
                <Typography fontWeight="bold" variant="caption">Operator</Typography>
                <Select
                    size="small"
                    value={operator}
                    onChange={e => setOperator(e.target.value)}
                    sx={{ fontSize: '0.8rem' }}
                >
                    {availableOperators.map(op => (
                        <MenuItem key={op} value={op}>
                            <Typography variant="caption">
                                {labelForOperator(op)}
                            </Typography>
                        </MenuItem>
                    ))}
                </Select>
            </>}

            {/* Value Field */}
            {!valueDisabled && <>
                <Typography fontWeight="bold" variant="caption">
                    {isMultiSelect ? 'Values' : 'Value'}
                </Typography>
                {availableValues === 'any-numeric' ? (
                    // if we have a numeric variable, show a text field
                    <TextField
                        size="small"
                        value={userEnteredValue}
                        onBlur={e => setUserEnteredValue(value?.toString() ?? '')}
                        onChange={e => setUserEnteredValue(e.target.value)}
                    />
                ) : (
                    // otherwise, we have a list of values, so show a dropdown
                    isMultiSelect ? (
                        <Select
                            multiple
                            size="small"
                            value={value || []}
                            onChange={e => setValue(e.target.value)}
                            sx={{ fontSize: '0.8rem' }}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {Array.isArray(selected) && selected.map((value) => (
                                        <Chip size="small" key={value} label={value} />
                                    ))}
                                </Box>
                            )}
                        >
                            {availableValues.map(v => (
                                <MenuItem key={v} value={v} style={{ height: 32 }} sx={{ pl: 0.0 }}>
                                    <Checkbox size="small" checked={((value || []) as string[]).includes(v)} />
                                    <ListItemText primary={<Typography variant="caption">{labelForValueOption(v)}</Typography>} />
                                </MenuItem>
                            ))}
                        </Select>
                    ) : (
                        <Select
                            size="small"
                            value={value}
                            onChange={e => setValue(e.target.value)}
                            sx={{ fontSize: '0.8rem' }}
                        >
                            {availableValues.map(v => (
                                <MenuItem key={v} value={v}>
                                    <Typography variant="caption">
                                        {labelForValueOption(v)}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Select>
                    )
                )}
            </>}

            {(!operatorDisabled || !valueDisabled) ? <>
                <Button
                    color={'primary'}
                    size={'small'}
                    style={{ 'marginBottom': '0px' }}

                    onClick={() => {
                        props.onFilterChanged({ variable: null, operator: null, value: null });
                        setVariable(null);
                        setOperator(null);
                        setValue(null);
                    }}
                >
                    Clear Filter
                </Button>
            </> : <Box height={10} />}
        </Box>
    );
};
