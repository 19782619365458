import { UserState, VehicleTrackingData } from '../types';
import {
    ClearRouteRefreshError,
    ClearUserCityUpdatedResponse,
    ClearUserDriverNotificationUpdatedResponse,
    ClearUserRouteUpdatedResponse,
    ReceiveAccountError,
    ReceiveRouteRefreshError,
    ReceiveUpdatedRoute,
    ReceiveUserCities,
    ReceiveUserCityCreated,
    ReceiveUserCityDeleted,
    ReceiveUserCityUpdated,
    ReceiveUserCityUpdatedError,
    ReceiveUserCityUpdatedSuccess,
    ReceiveUserDriverNotifications,
    ReceiveUserDriverNotificationCreated,
    ReceiveUserDriverNotificationDeleted,
    ReceiveUserDriverNotificationUpdated,
    ReceiveUserDriverNotificationUpdatedError,
    ReceiveUserDriverNotificationUpdatedSuccess,
    ReceiveUserRouteCreated,
    ReceiveUserRouteDeleted,
    ReceiveUserRoutes,
    ReceiveUserRouteUpdatedError,
    ReceiveUserRouteUpdatedSuccess,
    ReceiveUserVehicles,
    ReceiveVehicleNotificationsEnabled,
    StartedRouteRefresh,
    UserAction,
    OpenedPage
} from '../actions/User';
import {
    ClearPasswordResetState,
    PasswordResetSucceeded,
    ReceiveAccountInfo,
    ReceiveFeatureFlags,
    ReceiveLoginError,
    ReceiveLoginResponse,
    ReceivePasswordResetError,
    ReceiveUserProfile,
    StartedLogin,
    StartedLogout,
    StartedPasswordReset
} from '../actions/User';
import { setUserIdForAnalytics, setUserPropertyForAnalytics } from 'src/analytics';

export const logOutLocally = () => {
    // TODO: organize local storage keys into an enum of some sort?
    // only deleting the user-specific data because in most cases, a real user
    // will be logging back into the same account so we should keep their
    // preferences/seen status of dialogs
    const localStorageKeys = [
        'userId',
        'token',
        'portalToken',
        'clientName',
        'useLegacyTabDisplay',
        'selectedCity',
        // 'columnVisibilityModel',
        // 'lastDashboardViewState',
        // 'LAST_SEEN_WHATS_NEW',
        // 'SHOWN_WELCOME_DIALOG',
    ];
    for (const key of localStorageKeys) {
        delete localStorage[key];
    }
};

/**
 * this action is type is a lie because all actions pass through this reducer function
 * so the compiler thinks that the switch is exhaustive but it is not which is why
 * a return state; is needed at the end
 * 
 * @param state 
 * @param action 
 * @returns 
 */
export function user(state: UserState | undefined, action: typeof UserAction.actions): UserState {
    if (state === undefined) {
        let userId: number = -1;
        if (localStorage['userId']) {
            userId = parseInt(localStorage['userId']);
            if (isNaN(userId)) {
                userId = -1;
            }
        }
        // default to true for users who are already logged in
        let useLegacyTabDisplay = localStorage['token'] !== undefined;
        if (localStorage['useLegacyTabDisplay'] !== undefined) {
            useLegacyTabDisplay = localStorage['useLegacyTabDisplay'] === 'true';
        }
        return {
            id: userId,
            pictureURL: undefined,
            token: localStorage['token'],
            portalToken: localStorage['portalToken'],
            clientName: localStorage['clientName'],

            isLoggingIn: false,
            lastLoginError: undefined,

            submittingPasswordReset: false,
            passwordResetSuccess: false,
            passwordResetError: undefined,

            cities: [],
            citiesMetadata: { success: false, loading: false, error: undefined },
            savedRoutes: [],
            savedRoutesMetadata: { success: false, loading: false, error: undefined },
            vehicles: [],
            vehiclesMetadata: { success: false, loading: false, error: undefined },
            driverNotifications: [],
            refreshingRouteIds: [],
            refreshRouteError: undefined,
            editRouteResponse: undefined,
            editLocationResponse: undefined,
            editDriverNotificationResponse: undefined,

            showImpactTab: false,
            showRoutesTab: false,
            showDisruptionIndex: false,
            showWildfireIndices: false,
            show511InPortal: false,
            showEventsInPortal: false,
            useLegacyTabDisplay,
            myAccount: undefined,
            lastAccountError: undefined,

            currentPage: 'dashboard',
        };
    }

    switch (action.type) {
        case StartedLogin.type:
            return { ...state, isLoggingIn: true, lastLoginError: undefined };
        case StartedLogout.type:
            logOutLocally();

            return {
                ...state,
                token: undefined,
                clientName: undefined,
            };
        case ReceiveUserProfile.type:
            let newProfileState = {
                ...state,
                id: action.user['user']['id'] as number,
                clientName: action.user['user']['name'] as string,
            };

            try {
                localStorage['userId'] = newProfileState.id;
                localStorage['clientName'] = newProfileState.clientName;
            } catch (e) {
                console.error('failed to store user profile in local storage', e);
            }

            return newProfileState;
        case ReceiveUserCities.type:
            return { ...state, cities: [...action.cities], citiesMetadata: action.metadata };
        case ReceiveUserCityCreated.type:
            return { ...state, cities: [action.city, ...state.cities] };
        case ReceiveUserCityDeleted.type:
            return { ...state, cities: state.cities.filter(city => city.id !== action.city.id) };
        case ReceiveUserCityUpdated.type:
            let locations = [...state.cities];
            const cityIndex = locations.findIndex(r => r.id === action.city.id);
            if (cityIndex !== -1) {
                locations.splice(cityIndex, 1, action.city);
            } else {
                locations.push(action.city);
            }
            return { ...state, cities: locations };
        case ReceiveUserCityUpdatedSuccess.type:
            return { ...state, editLocationResponse: action.city };
        case ReceiveUserCityUpdatedError.type:
            return { ...state, editLocationResponse: action.errors };
        case ClearUserCityUpdatedResponse.type:
            return { ...state, editLocationResponse: undefined };
        case ReceiveUserDriverNotifications.type:
            return { ...state, driverNotifications: action.driverNotifications };
        case ReceiveUserDriverNotificationCreated.type:
            return { ...state, driverNotifications: [action.driverNotification, ...state.driverNotifications], editDriverNotificationResponse: action.driverNotification };
        case ReceiveUserDriverNotificationDeleted.type:
            return { ...state, driverNotifications: state.driverNotifications.filter(driverNotification => driverNotification.id !== action.driverNotification.id) };
        case ReceiveUserDriverNotificationUpdated.type:
            let notifications = [...state.driverNotifications];
            const notificationIndex = notifications.findIndex(n => n.id === action.driverNotification.id);
            if (notificationIndex !== -1) {
                notifications.splice(notificationIndex, 1, action.driverNotification);
            }
            return { ...state, driverNotifications: notifications };
        case ReceiveUserDriverNotificationUpdatedSuccess.type:
            return { ...state, editDriverNotificationResponse: action.driverNotification };
        case ReceiveUserDriverNotificationUpdatedError.type:
            return { ...state, editDriverNotificationResponse: action.errors };
        case ClearUserDriverNotificationUpdatedResponse.type:
            return { ...state, editDriverNotificationResponse: undefined };
        case ReceiveUserRoutes.type:
            return { ...state, savedRoutes: [...action.routes], savedRoutesMetadata: action.metadata, refreshingRouteIds: state.refreshingRouteIds ?? [] };
        case ReceiveUserRouteCreated.type:
            return { ...state, savedRoutes: [action.route, ...state.savedRoutes] };
        case ReceiveUserRouteDeleted.type:
            return { ...state, savedRoutes: state.savedRoutes.filter(route => route.id !== action.route.id) };
        case ReceiveUserRouteUpdatedSuccess.type:
            return { ...state, editRouteResponse: action.route };
        case ReceiveUserRouteUpdatedError.type:
            return { ...state, editRouteResponse: action.errors };
        case ClearUserRouteUpdatedResponse.type:
            return { ...state, editRouteResponse: undefined };
        case ReceiveUpdatedRoute.type:
            let routes = [...state.savedRoutes];
            const index = routes.findIndex(r => r.id === action.route.id);
            if (index !== -1) {
                routes.splice(index, 1, action.route);
            } else {
                routes.push(action.route);
            }
            return { ...state, savedRoutes: routes, refreshingRouteIds: state.refreshingRouteIds.filter(id => id !== action.route.id) };
        case StartedRouteRefresh.type:
            return { ...state, refreshingRouteIds: [...state.refreshingRouteIds, action.route.id!] };
        case ReceiveRouteRefreshError.type:
            return { ...state, refreshingRouteIds: state.refreshingRouteIds.filter(id => id !== action.route.id), refreshRouteError: action.error };
        case ClearRouteRefreshError.type:
            return { ...state, refreshRouteError: undefined };
        case ReceiveUserVehicles.type:
            return { ...state, vehicles: [...action.vehicles], vehiclesMetadata: action.metadata };
        case ReceiveVehicleNotificationsEnabled.type:
            let vehicles = [...state.vehicles];
            const updatedIndex = vehicles.findIndex(v => v.id === action.vehicle.id);
            if (updatedIndex !== -1) {
                let updatedVehicle: VehicleTrackingData = vehicles[updatedIndex];
                vehicles.splice(updatedIndex, 1, { ...updatedVehicle, notificationsEnabled: action.vehicle.notificationsEnabled });
            }
            return { ...state, vehicles: vehicles };
        case ReceiveFeatureFlags.type:
            localStorage['useLegacyTabDisplay'] = action.useLegacyTabDisplay.toString();
            return {
                ...state,
                showImpactTab: action.showImpactTab,
                showRoutesTab: action.showRoutesTab,
                showDisruptionIndex: action.showDisruptionIndex,
                showWildfireIndices: action.showWildfireIndices,
                show511InPortal: action.show511InPortal,
                showEventsInPortal: action.showEventsInPortal,
                useLegacyTabDisplay: action.useLegacyTabDisplay,
            };
        case ReceiveAccountInfo.type:
            return { ...state, myAccount: action.myAccount };
        case ReceiveAccountError.type:
            return { ...state, lastAccountError: action.error };
        case ReceiveLoginResponse.type:
            let newState = {
                ...state,
                id: action.user['id'] as number,
                token: action.user['token'] as string,
                portalToken: action.user['portal_token'] as string,
                clientName: action.user['name'] as string,
                lastLoginError: undefined,
                isLoggingIn: false,
                useLegacyTabDisplay: action.useLegacyTabDisplay,
            };

            try {
                localStorage['userId'] = newState.id;
                localStorage['token'] = newState.token;
                localStorage['portalToken'] = newState.portalToken;
                localStorage['clientName'] = newState.clientName;
                localStorage['useLegacyTabDisplay'] = newState.useLegacyTabDisplay;
            } catch (e) {
                console.error('failed to store login response in local storage', e);
            }

            setUserIdForAnalytics(`${newState.id}`);
            setUserPropertyForAnalytics('Client Name', newState.clientName);

            return newState;
        case ReceiveLoginError.type:
            return {
                ...state,
                lastLoginError: action.error,
                isLoggingIn: false,
            };
        case StartedPasswordReset.type:
            return {
                ...state,
                submittingPasswordReset: true,
                passwordResetSuccess: false,
                passwordResetError: undefined
            };
        case PasswordResetSucceeded.type:
            return {
                ...state,
                submittingPasswordReset: false,
                passwordResetSuccess: true,
                passwordResetError: undefined
            };
        case ReceivePasswordResetError.type:
            return {
                ...state,
                submittingPasswordReset: false,
                passwordResetSuccess: false,
                passwordResetError: action.error
            };
        case ClearPasswordResetState.type:
            return {
                ...state,
                submittingPasswordReset: false,
                passwordResetSuccess: false,
                passwordResetError: undefined
            };
        case OpenedPage.type:
            return {
                ...state,
                currentPage: action.page,
            };
    }

    return state;
}
