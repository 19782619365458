import {
    RatingsDataWithLocationInfo,
    RatingsState, LoadableResult,
} from '../types';
import {
    LoadBlurbsStart,
    RatingsAction,
    ReceiveBlurbsData, ReceiveImpactTilesetsData, ReceiveWeatherTilesetsData,
    ReceiveRatingsData,
    ReceiveWildfireRatingsData,
    ReceiveGovernmentalAlertData,
    ReceiveSubhourlyImpactTilesetsData,
    ReceiveSubhourlyWeatherTilesetsData
} from '../actions/Ratings';

export const coerceDateToHour = (date: Date) => {
    let d = new Date(date);
    if (Math.abs(d.getTimezoneOffset()) % 60 === 30) {
        // half hour timezones: need special handling, if you set the minute to zero on them then it'll come up with an invalid model run time because it'll be 30 min early when converted back to UTC
        d.setMinutes(30);
    } else if (d.getTimezoneOffset() % 60 === -45) {
        // 45-min timezones: need even more special handling: don't ask me. just JS things.
        d.setMinutes(45);
    } else {
        d.setMinutes(0);
    }
    d.setSeconds(0);
    d.setMilliseconds(0);
    return d;
};

export const areCoordsEqual = (a: number, b: number) => {
    return Math.abs(a - b) < 0.000000001;
};

const combineRatingsAndWildfireData = (ratingsData: LoadableResult<RatingsDataWithLocationInfo>, wildfireData: LoadableResult<RatingsDataWithLocationInfo>) => {
    if (ratingsData.value) {
        ratingsData.value!.wildfire_conditions = wildfireData.value?.wildfire_conditions ?? [];
        ratingsData.value!.wildfire_spread = wildfireData.value?.wildfire_spread ?? [];
    }
    return ratingsData;
};

export function ratings(state: RatingsState, action: typeof RatingsAction.actions): RatingsState {
    switch (action.type) {
        case ReceiveRatingsData.type:
            if (action.subhourly) {
                return {
                    ...state,
                    subhourlyRatingsData: combineRatingsAndWildfireData(action.ratingsData, state.wildfireData),
                };
            }
            return {
                ...state,
                ratingsData: combineRatingsAndWildfireData(action.ratingsData, state.wildfireData),
            };
        case ReceiveWildfireRatingsData.type:
            return {
                ...state,
                ratingsData: combineRatingsAndWildfireData(state.ratingsData, action.ratingsData),
                subhourlyRatingsData: combineRatingsAndWildfireData(state.subhourlyRatingsData, action.ratingsData),
                wildfireData: action.ratingsData,
            };
        case LoadBlurbsStart.type:
            return { ...state, blurbsAbortController: action.abortController };
        case ReceiveBlurbsData.type:
            return { ...state, blurbs: action.blurbs, blurbsAbortController: undefined };
        case ReceiveImpactTilesetsData.type:
            return {
                ...state,
                impactTilesets: action.impactTilesetsResult,
            };
        case ReceiveSubhourlyImpactTilesetsData.type:
            return {
                ...state,
                subhourlyImpactTilesets: action.impactTilesetsResult,
            };
        case ReceiveWeatherTilesetsData.type:
            return {
                ...state,
                weatherTilesets: action.weatherTilesetsResult,
            };
        case ReceiveSubhourlyWeatherTilesetsData.type:
            return {
                ...state,
                subhourlyWeatherTilesets: action.weatherTilesetsResult,
            };
        case ReceiveGovernmentalAlertData.type:
            return {
                ...state,
                governmentalAlerts: action.governmentalAlertsResult,
            };
    }

    let emptyCacheInfo = {
        road: { expiresAt: new Date() },
        disruption: { expiresAt: new Date() },
        flood: { expiresAt: new Date() },
        power: { expiresAt: new Date() },
        life_property: { expiresAt: new Date() },
        wildfire_spread: { expiresAt: new Date() },
        wildfire_conditions: { expiresAt: new Date() },
    };

    return state || {
        ratingsData: new LoadableResult(5 * 60),
        subhourlyRatingsData: new LoadableResult(5 * 60),
        wildfireData: new LoadableResult(5 * 60),
        blurbs: {},
        tilesets: [],
        isFetching: false,
        cacheInfo: emptyCacheInfo,
        subhourlyCacheInfo: emptyCacheInfo,

        governmentalAlerts: new LoadableResult(5 * 60),
        impactTilesets: new LoadableResult(5 * 60),
        weatherTilesets: new LoadableResult(5 * 60),
        subhourlyImpactTilesets: new LoadableResult(5 * 60),
        subhourlyWeatherTilesets: new LoadableResult(5 * 60),
    };
}
