import * as React from "react";
import {
    DataGridPro,
    GridColumns,
    GridFilterModel,
    GridLinkOperator,
    GridRenderCellParams,
    GridSortModel,
    GridValueGetterParams,
    gridFilteredSortedRowIdsSelector,
    useGridApiRef
} from '@mui/x-data-grid-pro';
import { LoadableResultMetadata, VehicleImpact, VehicleImpactTag, VehicleInsightTag, VehicleTrackingData } from '../../../types';
import { Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, TextField, Tooltip } from "@mui/material";
import { RouteData, tagColorForImpactLevel } from "src/types/routes";
import { ExpandLess, Route, WarningAmberOutlined } from "@mui/icons-material";
import { INFO_EMAIL } from "src/constants";
import { Spacer } from "src/components/Public/PricingCalculatorEmbed";
import { TablePaginationActions } from "src/components/shared/TablePaginationActions";
import { DashboardView } from "./ImpactDetailView";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { findMatchingRouteForVehicle } from "./data";
import { Config } from "src/components/shared/useConfig";

function arrayEquals(a: any, b: any) {
    return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
}

interface Props {
    vehicles: VehicleTrackingData[];
    vehiclesMetadata: LoadableResultMetadata;
    totalVehicleCount: number;
    routes: RouteData[];
    routesMetadata: LoadableResultMetadata;
    selectedVehicle?: VehicleTrackingData;
    selectedView?: string;
    filterNoImpactVehicles?: boolean;
    searchQuery: string;
    onVehicleSelected: (vehicle: VehicleTrackingData) => void;
    onVehicleMessageRequested: (vehicle: VehicleTrackingData, message: string, urgent: boolean) => void;
    onViewSelected: (view: DashboardView) => void;
}

export interface SendAlertData {
    vehicle: string;
    message: string;
    source: string;
}

export const SendAlertDialog = ({ alertData, selectedVehicle, showAlertDialog, setShowAlertDialog, onVehicleMessageRequested }: { alertData: SendAlertData, selectedVehicle: VehicleTrackingData | undefined, showAlertDialog: boolean; setShowAlertDialog: (open: boolean) => void; onVehicleMessageRequested: (vehicle: VehicleTrackingData, message: string, urgent: boolean) => void }) => {
    const [message, setMessage] = React.useState<string>(alertData.message);
    const [useVoice, setUseVoice] = React.useState<boolean>(false);
    const inputRef = React.useRef<HTMLInputElement>();

    React.useEffect(() => {
        // this seems to be the only way to get a textfield focus inside a dialog
        const timeout = setTimeout(() => {
            inputRef.current?.focus();
            inputRef.current?.setSelectionRange(inputRef.current?.value.length, inputRef.current?.value.length);
        }, 10);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    const handleCancel = () => {
        setShowAlertDialog(false);
    };
    const handleClose = () => {
        setShowAlertDialog(false);

        onVehicleMessageRequested(selectedVehicle!, message, useVoice);
    };

    return (
        <Dialog open={showAlertDialog} scroll={'paper'} maxWidth={'sm'} fullWidth>
            <DialogTitle>Send Alert to {alertData.vehicle}</DialogTitle>
            <DialogContent>
                <TextField
                    inputRef={inputRef}
                    fullWidth
                    value={message}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setMessage(event.target.value);
                    }}
                    multiline
                    rows={5}
                    maxRows={5}
                />
            </DialogContent>
            <DialogActions>
                {alertData.source === "platform_science" &&
                    <FormControlLabel style={{ marginLeft: '5px' }} label="Urgent? (will be read out loud if wheels in motion)" control={<Checkbox checked={useVoice} onChange={() => setUseVoice(!useVoice)} />} />
                }
                <Spacer stretch={true} />
                <Button style={{ color: '#bbb' }} onClick={handleCancel}>Cancel</Button>
                <Button style={{ marginRight: '7px' }} onClick={handleClose}>Send Alert</Button>
            </DialogActions>
        </Dialog>
    );
};

export const ImpactedVehiclesTable = (props: Props) => {
    const { vehicles, vehiclesMetadata, totalVehicleCount, routes, routesMetadata, selectedVehicle, selectedView, filterNoImpactVehicles, onViewSelected } = props;

    const [sortModel, setSortModel] = React.useState<GridSortModel>([{ field: 'currentImpact', sort: 'desc' }]);

    const [expandedRowId, setExpandedRowId] = React.useState<string>('');

    const [showAlertDialog, setShowAlertDialog] = React.useState<boolean>(false);
    const [alertData, setAlertData] = React.useState<SendAlertData>({ vehicle: '', message: '', source: '' });

    const shouldShowRouteVehiclePairs = Config.getBoolean(Config.Key.ShowRouteVehiclePairs);

    const prevTableState = React.useRef<GridInitialStatePro>();
    let initialTableState: GridInitialStatePro | undefined = undefined;
    let initialState = window.localStorage.getItem('vehiclesTableState');
    if (initialState) {
        initialTableState = JSON.parse(initialState) as GridInitialStatePro;
    }

    function getTagLabel(tag: VehicleImpactTag | VehicleInsightTag) {
        let label = tag.text.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

        if (tag.value) {
            label += ` (${tag.value.toFixed(1)})`;
        }

        return label;
    }

    function getTagWidth(text: string, font: string): number {
        const canvas: HTMLCanvasElement = document.getElementsByTagName("canvas")[0] || document.createElement("canvas");
        const context: CanvasRenderingContext2D | null = canvas.getContext("2d");
        if (context) {
            context.font = font;
            const metrics = context?.measureText(text);
            return metrics?.width + 21; // add padding and margin to text length
        }
        return -1;
    }

    function getFont() {
        const el = document.body;
        const fontWeight = window.getComputedStyle(el, null).getPropertyValue('font-weight') || 'normal';
        const fontSize = window.getComputedStyle(el, null).getPropertyValue('font-size') || '16px';
        const fontFamily = window.getComputedStyle(el, null).getPropertyValue('font-family') || 'Times New Roman';

        return `${fontWeight} ${fontSize} ${fontFamily}`;
    }

    function getTagWrapPoint(tags: VehicleImpactTag[] | VehicleInsightTag[], colWidth: number) {
        let colRemainder = colWidth;
        let wrapPoint = -1;
        tags.every((tag: VehicleInsightTag, index: number) => {
            colRemainder = colWidth;
            colWidth -= getTagWidth(getTagLabel(tag), getFont());
            if (colWidth < 0) {
                if (colRemainder - getTagWidth(`+${tags.length - wrapPoint}`, getFont()) < 0) {
                    wrapPoint = index - 1;
                } else {
                    wrapPoint = index;
                }
                return false;
            }
            return true;
        });
        return wrapPoint;
    }

    // columns are memoized to avoid infinite render glitch when passing sortModel
    // https://codesandbox.io/s/infinite-render-sortmodel-forked-bwv2g?file=/src/Demo.tsx:1528-1575
    const columns = React.useMemo<GridColumns>(() => [{
        field: 'id',
        hide: true
    }, {
        field: 'expand',
        headerName: '',
        width: 45,
        disableColumnMenu: true,
        renderCell: (params) => {
            if (expandedRowId !== params.row.id) return '';

            return (
                <div style={{ display: 'flex', alignContent: 'center' }} onClick={(event) => {
                    expandedRowId === params.row.id ? setExpandedRowId('') : setExpandedRowId(params.row.id);
                    event.preventDefault();
                }}>
                    <ExpandLess fontSize={'small'} />
                </div>
            );
        },
    },
    {
        field: 'name',
        headerName: 'Truck ID',
        minWidth: 150,
        disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) => {
            return params.row.name || params.row.externalId;
        },
        renderCell: (params: GridRenderCellParams) => {
            const truckId = params.value;
            if (truckId === undefined) return '';

            return (params.row.latitude === null || params.row.longitude === null) ? (
                <Tooltip title={'Vehicle is missing location data.'}>
                    <div style={{ display: 'flex', gap: '10px' }}>{truckId} <WarningAmberOutlined style={{ color: 'yellow' }} /></div>
                </Tooltip>
            )
                : (<div style={{ display: 'flex' }}>{truckId}</div>);
        },
    }, {
        field: 'destination',
        headerName: `Destination${shouldShowRouteVehiclePairs ? '' : ' (Coming Soon)'}`,
        minWidth: 150,
        disableColumnMenu: true,
        valueGetter: (params) => {
            if (!shouldShowRouteVehiclePairs) return '';

            const pairedRoute = findMatchingRouteForVehicle(params.row, routes);
            if (pairedRoute === undefined) return '';
            return pairedRoute.destination.label;
        }
    }, {
        field: 'currentImpact',
        headerName: 'Current Impact',
        minWidth: 150,
        disableColumnMenu: true,
        sortComparator: (v1, v2) => {
            if (v1 === undefined || v1.tagPriority === undefined) return -1;
            if (v2 === undefined || v2.tagPriority === undefined) return 1;

            return v1.tagPriority - v2.tagPriority;
        },
        valueGetter: (params) => {
            const impact = params.row.currentImpact;
            if (impact === undefined) return '';
            return impact;
        },
        renderCell: (params) => {
            const impact = params.row.currentImpact;
            if (impact === undefined) return '';

            const tags = impact.tags;
            let wrapPoint = getTagWrapPoint(tags, params.colDef.computedWidth || 0);

            const expandText: string = tags.length - wrapPoint === 1 ? `${tags.length - wrapPoint} more hazard` : `${tags.length - wrapPoint} more hazards`;
            const expandNum: string = `+${tags.length - wrapPoint}`;

            let impactCell: JSX.Element = (<div></div>);
            if (wrapPoint === -1 || tags.length === 1 || expandedRowId === params.row.id) {
                impactCell = (<>{tags.map((tag: VehicleImpactTag) => <Chip className={'vehicleTagDiv'} size="small" label={getTagLabel(tag)} style={{ backgroundColor: tagColorForImpactLevel(tag.impactLevel) }} />)}</>);
            } else {
                impactCell = (<>
                    {tags.slice(0, wrapPoint).map((tag: VehicleImpactTag) => <Chip className={'vehicleTagDiv'} size="small" label={getTagLabel(tag)} style={{ backgroundColor: tagColorForImpactLevel(tag.impactLevel) }} />)}
                    <Tooltip title={expandText}><Chip className={'vehicleTagDiv'} size="small" onClick={(event) => {
                        setExpandedRowId(params.row.id);
                        event.preventDefault();
                    }} label={expandNum} style={{ backgroundColor: '#0280dc', cursor: 'default' }} /></Tooltip>
                </>);
            }

            return (
                <div className={'vehicleCellDiv'}>
                    {impactCell}
                </div>
            );
        }
    }, {
        field: 'upcomingImpact',
        headerName: `Upcoming Impact${shouldShowRouteVehiclePairs ? '' : ' (Coming Soon)'}`,
        minWidth: 150,
        disableColumnMenu: true,
        sortComparator: (v1, v2) => {
            if (v1 === undefined || v1.tagPriority === undefined) return -1;
            if (v2 === undefined || v2.tagPriority === undefined) return 1;

            return v1.tagPriority - v2.tagPriority;
        },
        valueGetter: (params) => {
            if (!shouldShowRouteVehiclePairs) return undefined;
            return params.row.upcomingImpact;
        },
        renderCell: (params) => {
            const impact: VehicleImpact | undefined = params.value;
            if (impact === undefined) return '';

            const tags = impact.tags;
            let wrapPoint = getTagWrapPoint(tags, params.colDef.computedWidth || 0);

            const expandText: string = tags.length - wrapPoint === 1 ? `${tags.length - wrapPoint} more hazard` : `${tags.length - wrapPoint} more hazards`;
            const expandNum: string = `+${tags.length - wrapPoint}`;

            let impactCell: JSX.Element = (<div></div>);
            if (wrapPoint === -1 || tags.length === 1 || expandedRowId === params.row.id) {
                impactCell = (<>{tags.map((tag: VehicleImpactTag) => <Chip className={'vehicleTagDiv'} size="small" label={getTagLabel(tag)} style={{ backgroundColor: tagColorForImpactLevel(tag.impactLevel) }} />)}</>);
            } else {
                impactCell = (<>
                    {tags.slice(0, wrapPoint).map((tag: VehicleImpactTag) => <Chip className={'vehicleTagDiv'} size="small" label={getTagLabel(tag)} style={{ backgroundColor: tagColorForImpactLevel(tag.impactLevel) }} />)}
                    <Tooltip title={expandText}><Chip className={'vehicleTagDiv'} size="small" onClick={(event) => {
                        setExpandedRowId(params.row.id);
                        event.preventDefault();
                    }} label={expandNum} style={{ backgroundColor: '#0280dc', cursor: 'default' }} /></Tooltip>
                </>);
            }

            return (
                <div className={'vehicleCellDiv'}>
                    {impactCell}
                </div>
            );
        }
    }, {
        field: 'insights',
        headerName: 'Insights',
        minWidth: 150,
        disableColumnMenu: true,
        valueGetter: (params) => {
            const insight = params.row.currentInsight;
            if (insight === undefined) return '';
            return insight;
        },
        renderCell: (params) => {
            const insight = params.row.currentInsight;
            if (insight === undefined) return '';

            const tags = insight.tags;
            let wrapPoint = getTagWrapPoint(tags, params.colDef.computedWidth || 0);

            const expandText: string = tags.length - wrapPoint === 1 ? `${tags.length - wrapPoint} more insight` : `${tags.length - wrapPoint} more insights`;
            const expandNum: string = `+${tags.length - wrapPoint}`;

            let insightCell: JSX.Element = (<div></div>);
            if (wrapPoint === -1 || tags.length === 1 || expandedRowId === params.row.id) {
                insightCell = (<>{tags.map((tag: VehicleInsightTag) => <Tooltip title={tag.reason}><Chip className={'vehicleTagDiv'} size="small" label={getTagLabel(tag)} style={{ backgroundColor: tagColorForImpactLevel(tag.impactLevel) }} /></Tooltip>)}</>);
            } else {
                insightCell = (<>
                    {tags.slice(0, wrapPoint).map((tag: VehicleInsightTag) => <Tooltip title={tag.reason}><Chip className={'vehicleTagDiv'} size="small" label={getTagLabel(tag)} style={{ backgroundColor: tagColorForImpactLevel(tag.impactLevel) }} /></Tooltip>)}
                    <Tooltip title={expandText}><Chip className={'vehicleTagDiv'} size="small" onClick={(event) => {
                        setExpandedRowId(params.row.id);
                        event.preventDefault();
                    }} label={expandNum} style={{ color: '#0280dc', cursor: 'default' }} /></Tooltip>
                </>);
            }

            return (
                <div className={'vehicleCellDiv'} >
                    {insightCell}
                </div>
            );
        }
    }, {
        field: 'routeID',
        headerName: `Route${shouldShowRouteVehiclePairs ? '' : ' (Coming Soon)'}`,
        minWidth: 40,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
            if (!shouldShowRouteVehiclePairs) return '';
            const pairedRoute = findMatchingRouteForVehicle(params.row, routes);
            if (pairedRoute === undefined) return '';
            const pairedRouteDescription = `${pairedRoute.origin.label} to ${pairedRoute.destination.label}`;
            return (
                <Tooltip title={pairedRouteDescription} placement="top">
                    <IconButton onClick={() => onViewSelected('route' as DashboardView)}>
                        {<Route />}
                    </IconButton>
                </Tooltip>
            );
        }
    }, {
        // fake field name to prevent the header from being highlighted weirdly
        field: 'actions',
        headerName: 'Actions',
        minWidth: 120,
        disableColumnMenu: true,
        renderCell: (params) => {
            const vehicle: VehicleTrackingData = params.row;
            const source = vehicle.source;
            // TODO: move this logic to the server and return in /vehicles endpoint
            let issueWithSendingMessage: string | undefined = undefined;
            switch (source) {
                case "geotab":
                    break;
                case "samsara":
                    break;
                case "platform_science":
                    if (vehicle.externalDriverId === undefined) {
                        issueWithSendingMessage = "vehicle has no external driver id";
                    }
                    break;
                case "weatheroptics":
                    break;
                case "motive":
                    if (vehicle.externalDriverId === undefined) {
                        issueWithSendingMessage = "vehicle has no external driver id";
                    }
                    break;
                default:
                    issueWithSendingMessage = "unsupported vehicle source for messaging";
                    break;
            }
            const truckId = vehicle.name || vehicle.externalId;
            const insight = vehicle.currentInsight;
            const impact = vehicle.currentImpact;
            const tooltipTitle = issueWithSendingMessage || "";
            return (
                <Tooltip title={tooltipTitle} placement="top">
                    <span>
                        <Button onClick={() => {
                            let alertMessage = '';
                            if (insight && insight.tags.length > 0) {
                                insight.tags.forEach((tag: VehicleInsightTag) => {
                                    alertMessage += `${getTagLabel(tag)}: ${tag.reason}\n`;
                                });
                            } else if (impact) {
                                impact.tags.forEach((tag: VehicleImpactTag, index: number) => {
                                    const label = getTagLabel(tag);
                                    if (label === 'None') {
                                        return;
                                    }
                                    if (index === impact.tags.length - 1) {
                                        alertMessage += `${label}`;
                                    } else {
                                        alertMessage += `${label}, `;
                                    }
                                });
                            }

                            setAlertData({ vehicle: truckId, message: alertMessage, source: source });
                            setShowAlertDialog(true);
                        }} className={'sendAlertButton'} sx={{ "&:hover": { backgroundColor: "transparent" }, boxShadow: 0 }} disableRipple disableFocusRipple disabled={issueWithSendingMessage !== undefined} size="small">
                            Send Alert
                        </Button>
                    </span>
                </Tooltip >
            );
        }
    }], [vehicles, selectedVehicle, expandedRowId, shouldShowRouteVehiclePairs]);

    // logic to scroll to the correct page when a location is externally selected
    const [page, setPage] = React.useState(0);
    const pageSize = 5;

    const prevSelectedVehicle = React.useRef<VehicleTrackingData | undefined>();
    const prevSortModelRef = React.useRef<GridSortModel>();

    const apiRef = useGridApiRef();

    const filterModel: GridFilterModel = {
        items: [{
            id: 1,
            columnField: 'externalId',
            operatorValue: 'contains',
            value: props.searchQuery
        }, {
            id: 1,
            columnField: 'name',
            operatorValue: 'contains',
            value: props.searchQuery
        }, {
            id: 1,
            columnField: 'destination',
            operatorValue: 'contains',
            value: props.searchQuery
        }],
        linkOperator: GridLinkOperator.Or
    };

    // when the sort order changes, go to the first page of the results
    React.useEffect(() => {
        const prevSortModel = prevSortModelRef.current || [];
        if (arrayEquals(sortModel, prevSortModel)) return;

        setPage(0);

        prevSortModelRef.current = sortModel;
    }, [sortModel]);

    React.useEffect(() => {
        if (selectedVehicle === undefined) return;
        if (vehiclesMetadata.loading) return;
        if (routesMetadata.loading) return;

        const api = apiRef?.current;
        if (!api) return;

        const ids = gridFilteredSortedRowIdsSelector(apiRef);
        const index = ids?.findIndex(id => selectedVehicle.id === id);
        if (index === -1) return;

        const pageForSelectedVehicle = Math.floor(index / pageSize);
        if (page !== pageForSelectedVehicle) {
            // putting set page in set timeout to avoid null pointer when trying to set page on initial render
            // thie is a nkown and open bug for MUI datagrid https://github.com/mui/mui-x/issues/6411
            setTimeout(() => setPage(pageForSelectedVehicle), 0);
        }

        prevSelectedVehicle.current = selectedVehicle;
    }, [vehicles, selectedVehicle, props.searchQuery, expandedRowId, apiRef, selectedView]);


    return (
        <div className={'vehiclesTable'}>
            <DataGridPro
                components={{
                    NoRowsOverlay: () => {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                                <div style={{ display: 'inline-block', zIndex: 99, position: 'relative', padding: '5%' }}>
                                    {vehiclesMetadata.success && <p style={{ textAlign: 'center' }}>
                                        No {filterNoImpactVehicles ? 'impacted' : ''} vehicles found.
                                    </p>}
                                    {vehiclesMetadata.success && totalVehicleCount === 0 && <p style={{ textAlign: 'center' }}>
                                        If you&apos;re currently using Motive, Samsara, Platform Science, Revenova, Turvo, Geotab, or another ELD provider, please <a style={{ color: '#90CAF9' }} href={`mailto:${INFO_EMAIL}`}>contact us</a> to get your vehicle data shown here!
                                    </p>}
                                    {vehiclesMetadata.error !== undefined && <p style={{ textAlign: 'center' }}>
                                        There was an error while fetching your vehicles. Please refresh the page to try again. <a style={{ color: '#90CAF9' }} href={`mailto:${INFO_EMAIL}`}>Contact us</a> if the issue persists.
                                    </p>}
                                </div>
                            </div>
                        );
                    }
                }}
                componentsProps={{
                    pagination: {
                        ActionsComponent: TablePaginationActions
                    }
                }}
                apiRef={apiRef}
                columns={columns}
                rows={vehicles}
                pageSize={pageSize}
                page={page}
                pagination
                onPageChange={(page) => setPage(page)}
                autoHeight
                density={'compact'}
                filterModel={filterModel}
                sortModel={sortModel}
                onSortModelChange={(newValue) => setSortModel(newValue)}
                onStateChange={(e) => {
                    const current = apiRef.current.exportState();
                    if (JSON.stringify(prevTableState.current) !== JSON.stringify(current)) {
                        prevTableState.current = current as GridInitialStatePro;
                        window.localStorage.setItem('vehiclesTableState', JSON.stringify(current));
                    }
                }}
                initialState={initialTableState}
                onRowClick={(row, event) => props.onVehicleSelected(row.row as VehicleTrackingData)}
                selectionModel={selectedVehicle?.id}
            />
            {showAlertDialog && <SendAlertDialog alertData={alertData} selectedVehicle={selectedVehicle} showAlertDialog={showAlertDialog} setShowAlertDialog={setShowAlertDialog} onVehicleMessageRequested={props.onVehicleMessageRequested} />}
        </div>
    );
};
