import moment from "moment";
import { AlertData, DriverNotification, DriverNotificationFrequency, DriverNotificationThreshold, DriverNotificationType, ImpactSummary, LocationData, VehicleInsight, VehicleImpact, VehicleImpactTag, VehicleInsightTag, VehicleMessage, VehicleTrackingData, parseFloatOptional, WeatherTilesetsByIndex, ImpactTileset, WeatherRunTimes, ImpactTilesetsByIndex, ImpactRunTimes, SubhourlyImpactSummary, ImpactTag, ImpactTypeDetailed, VehicleCurrentConditions } from ".";
import { RatingKey } from "./RatingKey";
import { ImpactLevel, RouteData, RouteResultError, RouteResults, RouteWaypoint, TemperatureThresholdResult, VehicleData, WeatherFlag, wordForImpactLevel } from "./routes";
import { HistoricalStormEvent, HistoricalStormEventImpact, HistoricalStormEventReport, HistoricalStormEventRoute, HistoricalStormEventTile } from "src/components/Client/HistoricalAccuracy";
import { CouldNotReachServerError, DirectionsNotFoundError, GenericBadRequestError, InternalRouteRunError, InvalidDepartureTimeError, RouteEndOutOfRangeError, RouteOutOfModelBoundsError, RouteSaveError } from "src/actions/RoutesView";
import { AxiosResponse } from "axios";
import chroma from 'chroma-js';
import { decode, encode } from "@googlemaps/polyline-codec";

function parseDriverThreshold(json: any): DriverNotificationThreshold {
    return {
        id: json['id'],
        index: json['index'] as RatingKey,
        lowerBound: json['lowerBound'] as number,
        upperBound: json['upperBound'] as number,
    };
}

export function unmarshalDriverNotification(json: object): DriverNotification {
    let thresholds: DriverNotificationThreshold[] = [];
    if (json['thresholds'] && Array.isArray(json['thresholds'])) {
        try {
            thresholds = json['thresholds'].map(obj => parseDriverThreshold(obj));
        } catch (e) {
            console.error("error parsing thresholds in unmarshalDriverNotification:", e);
        }
    }

    let alerts: string[] = [];
    if (json['alerts']) {
        alerts = json['alerts'].split(',');
    }

    let template = '';
    if (json['template']) {
        template = json['template'];
    }

    return {
        id: json['id'] as number,
        name: json['name'] as string,
        status: json['status'] as string,
        notificationType: json['notification_type'] as DriverNotificationType,
        frequency: json['frequency'] as DriverNotificationFrequency,
        interval: json['interval'] as number,
        windowSize: json['window_size'] as number,
        thresholds,
        alerts,
        template,
        urgent: json['urgent'] as boolean,
    };
}

export function unmarshalImpactLevel(s: string): ImpactLevel {
    switch (s) {
        case 'none': return ImpactLevel.None;
        case 'low': return ImpactLevel.Low;
        case 'moderate': return ImpactLevel.Moderate;
        case 'high': return ImpactLevel.High;
        default: throw new Error(`invalid impact level literal ${s}`);
    }
}

function unmarshalImpactTags(json: any): ImpactTag[] {
    return json.map((tag: any) => {
        return {
            id: (tag['id'] ?? undefined) as string | undefined,
            source: tag['source'] as string,
            text: tag['text'] as string,
            value: (tag['value'] ?? undefined) as number | undefined,
            variable: (tag['variable'] ?? undefined) as string | undefined,
            impactLevel: unmarshalImpactLevel(tag['impact_level']),
            event: (tag['event'] ?? undefined) as string | undefined,
            description: (tag['description'] ?? tag['wo_event_briefing'] ?? undefined) as string | undefined,
            color: (tag['fill'] ?? undefined) as string | undefined,
            effective: (tag['effective'] ?? undefined) as string | undefined,
            expires: (tag['expires'] ?? undefined) as string | undefined,
        };
    });
}

export function parseImpactSummary(json: any): ImpactSummary {
    const impactLevel = unmarshalImpactLevel(json['impact_level']);
    const impactTypesDetailedRaw = json['impact_types_detailed'] ?? json['impact_types'].map((impactType: RatingKey) => {
        return {
            impact_level: wordForImpactLevel(impactLevel).toLowerCase(),
            impact_type: impactType,
        };
    });
    return {
        label: json['label'] as string,
        value: json['value'] as number,
        impactLevel: impactLevel,
        impactTypes: json['impact_types'] as RatingKey[],
        impactTypesDetailed: impactTypesDetailedRaw.map((tag: any): ImpactTypeDetailed => {
            return {
                impactLevel: unmarshalImpactLevel(tag['impact_level']),
                impactType: tag['impact_type'],
                impactValue: (tag['impact_value'] ?? undefined) as number | undefined,
            };
        }),
        peakImpactTimeMoment: moment(json['peak_impact_time_utc']).tz(json['time_zone_id'] as string),
        weatherFlags: json['weather_flags'] as WeatherFlag[],
    };
}

export function parseSubhourlyImpactSummary(json: any): SubhourlyImpactSummary {
    return {
        tags: unmarshalImpactTags(json['tags']),
        tagPriority: json['tag_priority'] as number,
        impactLevel: unmarshalImpactLevel(json['overall_impact_level']),
        lastUpdatedAt: new Date(Date.parse(json['last_updated_at'])),
        sourceRunTime: json['source_run_time'] !== 'unavailable' ? new Date(Date.parse(json['source_run_time'])) : undefined,
        source: json['source'],
    };
}

export function unmarshalLocation(json: object, needsGeocoding: boolean = false): LocationData {
    let subhourlyImpactSummary: SubhourlyImpactSummary | undefined = undefined;
    if (json['subhourly_impacts']) {
        subhourlyImpactSummary = parseSubhourlyImpactSummary(json['subhourly_impacts']);
    }
    let extendedImpactSummary: SubhourlyImpactSummary | undefined = undefined;
    if (json['extended_impacts']) {
        extendedImpactSummary = parseSubhourlyImpactSummary(json['extended_impacts']);
    }

    let impactSummaries: ImpactSummary[] = [];
    let impactSummariesUpdatedAt: Date | undefined;
    let impactSummariesRatingRunTime: Date | undefined;
    let timezone: string | undefined = json['time_zone'] ?? undefined;
    if (json['impact_summaries']) {
        if (Array.isArray(json['impact_summaries'])) {
            try {
                impactSummaries = json['impact_summaries'].map(obj => parseImpactSummary(obj));
                if (json['impact_summaries_updated_at']) {
                    impactSummariesUpdatedAt = new Date(Date.parse(json['impact_summaries_updated_at']));
                }
                if (json['impact_summaries_rating_run_time']) {
                    impactSummariesRatingRunTime = new Date(Date.parse(json['impact_summaries_rating_run_time']));
                }
            } catch (e) {
                console.error("error parsing impact summaries in unmarshalLocation:", e);
            }
        } else {
            try {
                impactSummaries = json['impact_summaries']['timeframes'].map((obj: any) => parseImpactSummary(obj));
                if (json['impact_summaries']['updated_at']) {
                    impactSummariesUpdatedAt = new Date(Date.parse(json['impact_summaries']['updated_at']));
                }
                if (json['impact_summaries']['rating_run_time']) {
                    impactSummariesRatingRunTime = new Date(Date.parse(json['impact_summaries']['rating_run_time']));
                }
            } catch (e) {
                console.error("error parsing impact summaries in unmarshalLocation:", e);
            }
        }
    }

    let zip = json['zip'];
    if (zip === null) {
        // undefined is cleaner - allows using optional type
        zip = undefined;
    }

    return {
        id: json['id'] as number,
        assetType: 'location',
        name: json['name'] as string,
        latitude: json['latitude'] as number,
        longitude: json['longitude'] as number,
        zip,
        timezone,
        neLatitude: parseFloatOptional(json['ne_lat']),
        neLongitude: parseFloatOptional(json['ne_lng']),
        swLatitude: parseFloatOptional(json['sw_lat']),
        swLongitude: parseFloatOptional(json['sw_lng']),
        needsGeocoding,
        impactSummaries,
        impactSummariesUpdatedAt,
        impactSummariesRatingRunTime,
        subhourlyImpactSummary,
        extendedImpactSummary,
        userMetadata: json['user_metadata'] as { [key: string]: any },
    };
}

export function unmarshalVehicle(json: any): VehicleTrackingData {
    let vehicleCurrentImpacts: VehicleImpact | undefined = undefined;
    const currentImpact = json['current_impacts'];
    // TODO: unmarshal proper sources instead of guessing
    if (currentImpact && currentImpact['tags']) {
        const tags: VehicleImpactTag[] = currentImpact['tags'].map((tag: any): VehicleImpactTag => {
            return {
                source: (tag['source'] ?? undefined) as string | undefined ?? 'unknown',
                id: (tag['id'] ?? undefined) as string | undefined,
                text: tag['text'] as string,
                value: (tag['value'] ?? undefined) as number | undefined,
                impactLevel: unmarshalImpactLevel(tag['impact_level']),
            };
        });
        // we only show the tag text and not issued time so some tags can be dupicated, 
        // remove duplicates so the impact alerts only show up once
        const uniqueTags = tags.reduce((accumulator: VehicleImpactTag[], current: VehicleImpactTag) => {
            if (!accumulator.find((tag: VehicleImpactTag) => tag.text === current.text)) {
                accumulator.push(current);
            }
            return accumulator;
        }, []);
        vehicleCurrentImpacts = {
            tags: uniqueTags,
            tagPriority: parseInt(currentImpact['tag_priority']) as number,
            overallImpactLevel: unmarshalImpactLevel(currentImpact['overall_impact_level']),
            lastUpdatedAt: new Date(Date.parse(currentImpact['last_updated_at'])),
        };
    }

    let vehicleInsights: VehicleInsight | undefined = undefined;
    const currentInsight = json['current_insights'];
    if (currentInsight && currentInsight['tags']) {
        const tags: VehicleInsightTag[] = currentInsight['tags'].map((tag: object): VehicleInsightTag => {
            return {
                source: 'insight',
                text: tag['text'] as string,
                reason: tag['reason'] as string,
                impactLevel: unmarshalImpactLevel(tag['impact_level']),
            };
        });
        vehicleInsights = { tags };
    }

    let vehicleUpcomingImpacts: VehicleImpact | undefined = undefined;
    const upcomingImpact = json['upcoming_impacts'];
    if (upcomingImpact && upcomingImpact['tags']) {
        const tags: VehicleImpactTag[] = upcomingImpact['tags'].map((tag: object): VehicleImpactTag => {
            return {
                source: (tag['source'] ?? undefined) as string | undefined ?? 'unknown',
                id: (tag['id'] ?? undefined) as string | undefined,
                text: tag['text'] as string,
                value: (tag['value'] ?? undefined) as number | undefined,
                impactLevel: unmarshalImpactLevel(tag['impact_level']),
            };
        });
        // we only show the tag text and not issued time so some tags can be dupicated, 
        // remove duplicates so the impact alerts only show up once
        const uniqueTags = tags.reduce((accumulator: VehicleImpactTag[], current: VehicleImpactTag) => {
            if (!accumulator.find((tag: VehicleImpactTag) => tag.text === current.text)) {
                accumulator.push(current);
            }
            return accumulator;
        }, []);
        vehicleUpcomingImpacts = {
            tags: uniqueTags,
            tagPriority: parseInt(upcomingImpact['tag_priority']) as number,
            overallImpactLevel: unmarshalImpactLevel(upcomingImpact['overall_impact_level']),
            lastUpdatedAt: new Date(Date.parse(upcomingImpact['last_updated_at'])),
        };
    }

    let vehicleCurrentConditions: VehicleCurrentConditions | undefined = undefined;
    const currentConditions = json['current_conditions'];
    if (currentConditions && Object.keys(currentConditions).some((key: string) => key !== 'last_updated_at')) {
        vehicleCurrentConditions = unmarshalServerJSON(currentConditions, false) as VehicleCurrentConditions;
    }

    const speed = (json['speed'] ?? undefined) as number | undefined;
    const externalGroupIds = (json['external_group_ids'] ?? undefined) as string[] | undefined || [];
    const externalUpdatedAtStr = (json['external_updated_at'] ?? undefined) as string | undefined;
    const externalUpdatedAt = externalUpdatedAtStr ? new Date(Date.parse(externalUpdatedAtStr)) : undefined;

    return {
        id: `${json['id'] as number}`,
        assetType: 'vehicle',
        externalId: json['external_id'] as string,
        externalDriverId: (json['external_driver_id'] ?? undefined) as string | undefined,
        externalGroupIds,
        externalUpdatedAt: externalUpdatedAt,
        name: (json['name'] ?? undefined) as string | undefined,
        latitude: (json['latitude'] ?? undefined) as number | undefined,
        longitude: (json['longitude'] ?? undefined) as number | undefined,
        bearing: (json['bearing'] ?? undefined) as number | undefined,
        speed: speed,
        source: json['source'] as string,
        routeId: json['route_id'] as number,
        currentImpact: vehicleCurrentImpacts,
        currentInsight: vehicleInsights,
        currentConditions: vehicleCurrentConditions,
        upcomingImpact: vehicleUpcomingImpacts,
        notificationsEnabled: json['notifications_enabled'] === null ? true : json['notifications_enabled'] as boolean,
    };
}

export function unmarshalVehicleMessage(json: object): VehicleMessage {
    return {
        id: json['id'] as number,
        vehicleId: json['vehicle_id'] as number,
        vehicleExternalId: json['vehicle_external_id'] as string,
        vehicleName: json['vehicle_name'] as string,
        message: json['message'] as string,
        urgent: json['urgent'] as boolean,
        source: json['source'] as string,
        status: json['status'] as string,
        sentAt: json['created_at'] as Date,
        latitude: json['latitude'] as number,
        longitude: json['longitude'] as number,
        error: json['error'] as string,
    };
}

// TODO: quick hack to get the marker color we need, might want to adjust before merging
const powerV2ColorScale = chroma
    .scale([
        "#CBD0ED",
        "#CBD0ED",
        "#A8A5E6",
        "#8f7be1",
        "#844EE1",
        "#881CE4",
        "#AD09ED",
        "#DB02E9",
        "#D200AB",
        "#C70070",
        "#AC0062",
    ])
    .domain([0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0]);
// codepoint from https://fonts.google.com/icons
const historicalImpactIconMap = {
    "road": "\ue558",
    "flood": "\ue91c",
    "power": "\uea0b",
    "life & property": "\uf203",
    "disruption": "\ue002",
};
const historicalImpactPinColorMap = {
    "road": "red",
    "flood": "blue",
    "life & property": "#AF3524",
    "disruption": "pink",
};


function unmarshalHistoricalStormEventImpact(json: object): HistoricalStormEventImpact {
    const name = json['name'];
    const tiles = json['tiles']?.map((tile: HistoricalStormEventTile) => {
        return {
            forecastDaysOut: tile['forecast_days_out'],
            url: tile['url']
        };
    });
    const routes = json['routes']?.map((route: HistoricalStormEventRoute) => {
        const routesForDay = route['routes'].map((routeResult: RouteResults) => {
            return {
                forecastDaysOut: route['forecast_days_out'],
                description: routeResult['route_description'],
                optionsForRoute: parseRouteResults(routeResult['rightroute_response'])
            };
        });
        return routesForDay;
    });
    const groundTruthReports = json['ground_truth_reports'].map((report: HistoricalStormEventReport) => {
        let description: string;

        let markerColor: string;
        if (name === "Power") {
            const powerOutageFraction = parseFloat(report['description']);
            description = `${(powerOutageFraction * 100).toFixed(1)}% of customers without power`;
            markerColor = powerV2ColorScale(powerOutageFraction).hex();
        } else {
            description = report['description'];
            markerColor = historicalImpactPinColorMap[name.toLowerCase()];
        }
        return {
            location: {
                name: report['location_name'],
                zip: report['zip_code'],
                latitude: report['latitude'],
                longitude: report['longitude'],
            },
            polygon: report['polygon'],
            description,
            markerColor,
            markerIcon: historicalImpactIconMap[name.toLowerCase()],
        };
    });

    return { name, tiles, routes, groundTruthReports };
}

export function unmarshalHistoricalStormEvent(json: object): HistoricalStormEvent {
    const name = json['name'];
    const description = json['description'];
    const analysis = json['analysis'];
    const location = {
        neLatitude: json['location']['ne']['latitude'],
        neLongitude: json['location']['ne']['longitude'],
        swLatitude: json['location']['sw']['latitude'],
        swLongitude: json['location']['sw']['longitude'],
    };
    const impacts = json['impacts'].map((impact: object) => unmarshalHistoricalStormEventImpact(impact));
    // copy road ground truth reports to right route impact
    const rightRouteImpact = impacts.filter((impact: HistoricalStormEventImpact) => impact.name === 'RightRoute');
    if (rightRouteImpact[0]) {
        rightRouteImpact[0].groundTruthReports = impacts.filter((impact: HistoricalStormEventImpact) => impact.name === 'Road')[0].groundTruthReports;
    }
    const events = (json['events'] || []).map((eventsForDay: object) => {
        return eventsForDay['features'].map((eventJSON: object) => {
            const properties = eventJSON['properties']!;
            const eventType = properties['wo_event_type'];
            return {
                id: properties['storm_id'] as string,
                type: eventType,
                geoJson: eventJSON as unknown as GeoJSON.Feature,

                stormType: properties['storm_type'] as string,
                maxSnowAccumulation: properties['max_SNOW_ACCUM'] as number,
                maxFrozenRainAccumulation: properties['max_FRZR_ACCUM'] as number,
                maxRainAccumulation: properties['max_RAIN_ACCUM'] as number,
                maxGust: properties['max_GUST'] as number,
                avgPeakRoadIndex: properties['average_peak_wo_road_index'] as number,
                avgPeakFloodIndex: properties['average_peak_wo_flood_index'] as number,
                avgPeakDisruptionIndex: properties['average_peak_wo_disruption_index'] as number,
                avgPeakWindGust: properties['average_peak_wind_gust'] as number,
                weatherFlags: properties['weather_flags'] as string[],
                affectedCountries: properties['affected_countries'].map((country: any) => `${country['name']}`).join(", ") as string,
                startTime: new Date(properties['storm_start_time_iso'] as string),
                endTime: new Date(properties['storm_end_time_iso'] as string),
                briefing: properties['wo_event_briefing'] as string,
            };
        });
    });

    return { name, description, analysis, location, impacts, events };
}

export function parseRouteResults(rightrouteResponseBody: any): RouteResults[] {
    const validRoutes = rightrouteResponseBody.routes.filter((route: any) => route.success === true);
    const routes = [];
    if (validRoutes.length === 0) {
        throw new Error('no valid routes in RightRoute response but success was true');
    }

    for (const validRoute of validRoutes) {

        let weatherFlags: WeatherFlag[] = [];

        for (const flag of ['extreme_weather_flag', 'snow_flag', 'rain_flag', 'freezing_rain_flag', 'wind_flag', 'visibility_flag']) {
            if (validRoute.weather_summary[flag] === true) {
                weatherFlags.push(flag as WeatherFlag);
            }
        }

        let weatherAlerts: string[] = [];
        for (const alert of validRoute.weather_summary.weather_alerts) {
            weatherAlerts.push(alert['event']);
        }

        let aboveTemperatureThresholds = [];
        let belowTemperatureThresholds = [];
        for (const threshold in validRoute.temperature_summary?.thresholds) {
            const tempThreshold: TemperatureThresholdResult = {
                threshold: threshold,
                percentBreachingThreshold: validRoute.temperature_summary.thresholds[threshold].percent_of_route_breaching_threshold,
                secondsBreachingThreshold: validRoute.temperature_summary.thresholds[threshold].seconds_breaching_threshold
            };
            threshold.startsWith('<') ? belowTemperatureThresholds.push(tempThreshold) : aboveTemperatureThresholds.push(tempThreshold);
        }

        let slowdownPolylines = [];
        let roadRiskPolylines = [];
        if (validRoute.polylines_v2 !== undefined) {
            let polyline = undefined;
            // if both of the polylines are 1 item long (polyline is all green) we don't have to deceode/encode and can just use the polyline as is
            if (validRoute.polylines_v2.slowdown_segments.length > 1 || validRoute.polylines_v2.road_risk_segments.length > 1) {
                polyline = decode(validRoute.polylines_v2.polyline);
            }

            for (const segment of validRoute.polylines_v2.slowdown_segments) {
                slowdownPolylines.push({
                    route: validRoute,
                    polyline: polyline ? encode(polyline.slice(segment.start_index, segment.end_index + 1)) : validRoute.polylines_v2.polyline,
                    color: segment.color,
                });
            }

            for (const segment of validRoute.polylines_v2.road_risk_segments) {
                let polylineWeatherFlags: WeatherFlag[] = [];

                for (const flag of ['extreme_weather_flag', 'snow_flag', 'rain_flag', 'freezing_rain_flag', 'wind_flag', 'visibility_flag']) {
                    if (segment.weather_summary[flag] === true) {
                        polylineWeatherFlags.push(flag as WeatherFlag);
                    }
                }
                roadRiskPolylines.push({
                    route: validRoute,
                    polyline: polyline ? encode(polyline.slice(segment.start_index, segment.end_index + 1)) : validRoute.polylines_v2.polyline,
                    color: segment.color,
                    maxRoadIndex: parseFloat(segment.max_wo_road_index),
                    weatherFlags: polylineWeatherFlags
                });
            }
        } else {
            slowdownPolylines = validRoute.slowdown_polylines;

            for (const segment of validRoute.road_risk_polylines) {
                let polylineWeatherFlags: WeatherFlag[] = [];

                for (const flag of ['extreme_weather_flag', 'snow_flag', 'rain_flag', 'freezing_rain_flag', 'wind_flag', 'visibility_flag']) {
                    if (segment.weather_summary[flag] === true) {
                        polylineWeatherFlags.push(flag as WeatherFlag);
                    }
                }
                roadRiskPolylines.push({
                    route: validRoute,
                    polyline: segment.polyline,
                    color: segment.color,
                    maxRoadIndex: parseFloat(segment.max_wo_road_index),
                    weatherFlags: polylineWeatherFlags
                });
            }
        }

        routes.push({
            origin: {
                label: rightrouteResponseBody.origin.address || 'Origin',
                latitude: rightrouteResponseBody.origin.latitude,
                longitude: rightrouteResponseBody.origin.longitude,
            },
            destination: {
                label: rightrouteResponseBody.destination.address || 'Destination',
                latitude: rightrouteResponseBody.destination.latitude,
                longitude: rightrouteResponseBody.destination.longitude,
            },
            slowdownFraction: validRoute.slowdown_summary.wo_weather_percent_slowdown as number,
            adjustedArrivalTime: new Date(Date.parse(validRoute.arrival_time_summary.wo_adjusted_arrival_time as string)),
            // convert meters to miles
            distanceMiles: validRoute.route_info.distance * 0.00062,
            weatherFlags,
            weatherAlerts,
            slowdownPolylines,
            roadRiskPolylines,
            updatedAt: new Date(),
            maxRoadIndex: validRoute.impact_summary.max_wo_road_index,
            maxCrashIndex: validRoute.impact_summary.max_wo_crash_index,
            aboveTemperatureThresholds,
            belowTemperatureThresholds
        });
    }

    return routes;
}

export function parseRouteError(rightRouteResponse: AxiosResponse<any> | undefined, body: any): Error | undefined {
    if (body.error === 'route returned no results, directions could not be found') {
        return new DirectionsNotFoundError();
    } else if (body.error === 'internal server error') {
        return new InternalRouteRunError();
    } else if (body.error && body.error.indexOf('departure_time is in the past') === 0) {
        return new InvalidDepartureTimeError();
    } else if (body.error === 'route ends outside of forecast range, please specify an earlier departure_time or a shorter route') {
        return new RouteEndOutOfRangeError();
    } else if (body.error && body.error.indexOf('route ends outside of model bounds, please specify a route in') === 0) {
        return new RouteOutOfModelBoundsError(body.error.substring(body.error.indexOf('in ') + 3));
    } else if (typeof body.error === 'string' && (rightRouteResponse === undefined || rightRouteResponse.status === 400)) {
        // pass along all bad request errors
        // if we have the response object, we check that it was a 400; otherwise, we'll assume that it's a 400
        return new GenericBadRequestError(body.error);
    } else if (!body.success) {
        return new Error('RightRoute response had global success flag marked false but unhandled error');
    }
    return undefined;
}

export function getUserDescriptionOfError(error: Error | undefined) {
    let errorTitle: string | undefined;
    let errorMessage: string | undefined;
    if (error instanceof DirectionsNotFoundError) {
        errorTitle = "Directions not found";
        errorMessage = "Please try changing locations or contact us.";
    } else if (error instanceof InternalRouteRunError) {
        errorTitle = "Route failed to run";
        errorMessage = "Please try changing locations or departure time, or try again later. If this problem persists, please contact us.";
    } else if (error instanceof CouldNotReachServerError) {
        errorTitle = "Could not reach server";
        errorMessage = "Please check your internet connection, or try again later.";
    } else if (error instanceof InvalidDepartureTimeError) {
        errorTitle = "Invalid departure time";
        errorMessage = "Please ensure your departure time is in the future.";
    } else if (error instanceof RouteEndOutOfRangeError) {
        errorTitle = "Route ends outside of forecast range";
        errorMessage = "Please either select an earlier departure time or pick a shorter route.";
    } else if (error instanceof RouteOutOfModelBoundsError) {
        errorTitle = "Route outside of model bounds";
        errorMessage = `Please select a route in ${error.location}.`;
    } else if (error instanceof GenericBadRequestError) {
        errorTitle = "Error";
        errorMessage = `${error.message}.\n\nIf this does not help you resolve the issue, or this error happens repeatedly, please contact us.`;
    } else if (error instanceof RouteSaveError) {
        errorTitle = "Error saving route";
        errorMessage = error.message;
    } else {
        errorTitle = "Unknown error";
        errorMessage = "Please try again later or contact us.";
    }
    return { errorTitle, errorMessage };
}

export function unmarshalRoute(json: object): RouteData {
    let routeResults: RouteResults[] | undefined;
    let routeResultError: RouteResultError | undefined;
    let routeResultsUpdatedAt: Date | undefined;
    let waypoints: RouteWaypoint[] | undefined;

    const latestRightrouteResponse = json['latest_rightroute_response'];
    if (latestRightrouteResponse && json['rightroute_response_updated']) {
        try {
            const error = parseRouteError(undefined, latestRightrouteResponse);
            if (error) {
                routeResults = undefined;
                routeResultError = { error };
            } else {
                routeResults = parseRouteResults(latestRightrouteResponse);
                routeResultError = undefined;
            }
            routeResultsUpdatedAt = new Date(Date.parse(json['rightroute_response_updated'] as string));
        } catch (e) {
            console.error("error parsing route results in unmarshalRoute:", e);
        }
    }

    const routeWaypoints = json['waypoints'];
    if (routeWaypoints) {
        waypoints = routeWaypoints.split('|').map((wp: string) => {
            const parts = wp.split(';');
            const latLong = parts[0].split(',');
            return {
                label: wp,
                latitude: parseFloat(latLong[0]),
                longitude: parseFloat(latLong[1]),
                stopDuration: parts.length === 2 ? parseInt(parts[1].split('=')[1]) : undefined
            };
        });
    }

    let vehicleData: VehicleData | undefined = undefined;
    if (json['vehicle_id']) {
        vehicleData = { id: `${json['vehicle_id']}` };
        if (json['vehicle_type']) vehicleData.type = json['vehicle_type'];
        if (json['vehicle_height']) vehicleData.height = json['vehicle_height'];
        if (json['vehicle_width']) vehicleData.width = json['vehicle_width'];
        if (json['vehicle_length']) vehicleData.length = json['vehicle_length'];
        if (json['vehicle_weight']) vehicleData.weight = json['vehicle_weight'];
        if (json['vehicle_axle_weight']) vehicleData.axleWeight = json['vehicle_axle_weight'];
    }

    return {
        id: json['id'] as number,
        assetType: 'route',
        name: json['name'] as string | undefined,
        externalId: json['external_id'] as string,
        origin: {
            latitude: parseFloat(json['origin_lat']),
            longitude: parseFloat(json['origin_long']),
            label: json['origin_label'] as string,
        },
        destination: {
            latitude: parseFloat(json['destination_lat']),
            longitude: parseFloat(json['destination_long']),
            label: json['destination_label'] as string,
        },
        departureTime: new Date(Date.parse(json['departure_time'] as string)),
        waypoints: waypoints,
        vehicleData: vehicleData,
        selectedRouteOption: `${json['id']} Option 1`,
        latestRouteResults: routeResults,
        latestRouteResultError: routeResultError,
        routeResultsUpdatedAt: routeResultsUpdatedAt,
    };
}

export function unmarshalCachedRoute(json: object): RouteData {
    let routeResults: RouteResults[] | undefined;
    let routeResultError: RouteResultError | undefined;
    let waypoints: RouteWaypoint[] | undefined;

    const latestRightrouteResponse = json;
    if (latestRightrouteResponse) {
        try {
            const error = parseRouteError(undefined, latestRightrouteResponse);
            if (error) {
                routeResults = undefined;
                routeResultError = { error };
            } else {
                routeResults = parseRouteResults(latestRightrouteResponse);
                routeResultError = undefined;
            }
        } catch (e) {
            console.error("error parsing route results in unmarshalRoute:", e);
        }
    }

    const routeWaypoints = json['waypoints'];
    if (routeWaypoints) {
        waypoints = routeWaypoints.split('|').map((wp: string) => {
            const parts = wp.split(';');
            const latLong = parts[0].split(',');
            return {
                label: wp,
                latitude: parseFloat(latLong[0]),
                longitude: parseFloat(latLong[1]),
                stopDuration: parts.length === 2 ? parseInt(parts[1].split('=')[1]) : undefined
            };
        });
    }

    let vehicleData: VehicleData | undefined = undefined;
    if (json['vehicle_id']) {
        vehicleData = { id: `${json['vehicle_id']}` };
        if (json['vehicle_type']) vehicleData.type = json['vehicle_type'];
        if (json['vehicle_height']) vehicleData.height = json['vehicle_height'];
        if (json['vehicle_width']) vehicleData.width = json['vehicle_width'];
        if (json['vehicle_length']) vehicleData.length = json['vehicle_length'];
        if (json['vehicle_weight']) vehicleData.weight = json['vehicle_weight'];
        if (json['vehicle_axle_weight']) vehicleData.axleWeight = json['vehicle_axle_weight'];
    }

    return {
        id: json['id'] as number,
        origin: {
            latitude: parseFloat(json['origin']['latitude']),
            longitude: parseFloat(json['origin']['longitude']),
            label: `${json['origin']['latitude']}, ${json['origin']['longitude']}`,
        },
        destination: {
            latitude: parseFloat(json['destination']['latitude']),
            longitude: parseFloat(json['destination']['longitude']),
            label: `${json['destination']['latitude']}, ${json['destination']['longitude']}`,
        },
        departureTime: new Date(Date.parse(json['departure_time'] as string)),
        waypoints: waypoints,
        vehicleData: vehicleData,
        selectedRouteOption: `Route Option 1`,
        latestRouteResults: routeResults,
        latestRouteResultError: routeResultError,
        routeResultsUpdatedAt: new Date(),
    };
}

export function unmarshalAlertData(alertJSON: object): AlertData {
    let instructionComponent: string = '';
    if (alertJSON['properties']['instruction']) {
        instructionComponent = `\n${alertJSON['properties']['instruction']}`;
    }
    return {
        details: {
            type: alertJSON['properties']['event'] as string,
            name: alertJSON['properties']['event'] as string,
            emergency: (alertJSON['properties']['severity'] === 'Severe') as boolean,
            color: alertJSON['properties']['fill'] as string,
            body: alertJSON['properties']['description'] as string,
            bodyFull: alertJSON['properties']['area_description'] + '\n' + alertJSON['properties']['description'] + instructionComponent as string,
        },

        active: true, // do we still need this?

        id: alertJSON['properties']['id'] as string,

        timestamps: {
            issued: new Date(Date.parse(alertJSON['properties']['sent'] as string)),
            begins: new Date(Date.parse(alertJSON['properties']['effective'] as string)),
            expires: new Date(Date.parse(alertJSON['properties']['expires'] as string)),
        },

        geojsonFeature: alertJSON,
    };
}

export function unmarshalIndividualTilesets(json: any[], variable: string): ImpactTileset[] {
    return json.map(obj => {
        return {
            // the URL of our tileset is currently unique so it can function as the ID
            id: obj.url as string,
            variable,
            time: new Date(obj.time * 1000),
            url: obj.url as string,
        };
    });
}

export function unmarshalWeatherTilesets(json: object): WeatherTilesetsByIndex {
    const keys = ['radar', 'temperature', 'rain_accumulation', 'snow_accumulation', 'wind_speed', 'wind_gust'];
    const output: Partial<WeatherTilesetsByIndex> = {};
    const weatherRunTimes: Partial<WeatherRunTimes> = {};
    for (const key of keys) {
        const rawTilesets = json[key];
        if (!rawTilesets) {
            continue;
        }
        let tilesets = unmarshalIndividualTilesets(rawTilesets, key);
        output[key] = tilesets;
        // getting runtime from the first tileset works as long as we pass full_forecast to tiling/weather
        // if we didn't, then the first tileset would be at the start of the current hour instead
        weatherRunTimes[key] = tilesets[0].time;
    }
    output.createdAt = Object.fromEntries(Object.entries(json['weather_tilesets_created_at'])
        .map(([key, timeString]) => [key, new Date(timeString as string)])) as unknown as WeatherRunTimes;
    output.runTimes = weatherRunTimes as WeatherRunTimes;
    return output as WeatherTilesetsByIndex;
}

export function unmarshalImpactTilesets(json: object): ImpactTilesetsByIndex {
    const keys = ['road', 'flood', 'power', 'disruption', 'life_property', 'wildfire_spread', 'wildfire_conditions'];
    const output: Partial<ImpactTilesetsByIndex> = {};
    const impactRunTimes: Partial<ImpactRunTimes> = {};
    for (const key of keys) {
        const rawTilesets = json[key];
        // wildfire_spread and wildfire_conditions could be missing because of permissions
        // so we skip if they're missing
        if (!rawTilesets) {
            continue;
        }
        let tilesets = unmarshalIndividualTilesets(rawTilesets, key);
        output[key] = tilesets;
        // getting runtime from the first tileset works as long as we pass full_forecast to tiling/weather
        // if we didn't, then the first tileset would be at the start of the current hour instead
        impactRunTimes[key] = tilesets[0].time;
    }
    output.createdAt = Object.fromEntries(Object.entries(json['impact_tilesets_created_at'])
        .filter(([key,]) => key !== 'road' && key !== 'power')
        .map(([key, timeString]) => {
            if (key === 'road_v2') {
                return ['road', new Date(timeString as string)];
            }
            if (key === 'power_v2') {
                return ['power', new Date(timeString as string)];
            }
            return [key, new Date(timeString as string)];
        })) as unknown as ImpactRunTimes;
    output.runTimes = impactRunTimes as ImpactRunTimes;
    return output as ImpactTilesetsByIndex;
}

export const snakeToCamelCase = (str: string) => str.replace(/_[a-z0-9]/g, letter => `${letter.substr(1).toUpperCase()}`);
export const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, (letter: string) => `_${letter.toLowerCase()}`);

export function unmarshalServerJSON(json: any, legacyNullTreatment: boolean = false): any {
    let unmarshalled = {};

    for (const key in json) {
        // eslint-disable-next-line no-prototype-builtins
        if (!json.hasOwnProperty(key)) continue;

        let transformedKey = snakeToCamelCase(key);

        let value = json[key];

        let transformedValue;
        if (typeof value === 'object') {
            if (value === null && !legacyNullTreatment) {
                transformedValue = null;
            } else {
                transformedValue = unmarshalServerJSON(value, legacyNullTreatment);
            }
        } else {
            transformedValue = value;
        }

        unmarshalled[transformedKey] = transformedValue;
    }

    return unmarshalled;
}
