export const WEATHER_CACHE_INTERVAL = 5 * 60 * 1000; // 5 minutes
export const RATINGS_CACHE_INTERVAL = 5 * 60 * 1000; // 5 minutes
export const EVENTS_CACHE_INTERVAL = 9.5 * 60 * 1000; // 9.5 minutes
export const ROAD_CONDITIONS_CACHE_INTERVAL = 15 * 60 * 1000; // 15 minutes

let apiHost;
if (process.env.REACT_APP_ENV === 'development') {
    apiHost = "http://localhost:3000";
} else if (process.env.REACT_APP_ENV === 'staging') {
    apiHost = "https://staging-api.weatheroptics.co";
} else {
    apiHost = "https://api.weatheroptics.co";
}

let granularRatingsHost;
let rightrouteHost;
let forecastHost;
let impactTilesHost;
let alertsHost;
let eventsHost;
let roadStatusHost;
let historicalStormDataURLPrefix;
if (process.env.REACT_APP_ENV === 'development') {
    granularRatingsHost = "http://localhost:1420/impact";
    rightrouteHost = "http://localhost:5000/rightroute";
    forecastHost = "http://localhost:1420/weather/forecast";
    impactTilesHost = "http://localhost:45335";
    alertsHost = "http://localhost:1420/alerts";
    eventsHost = "http://localhost:1420/events";
    roadStatusHost = "http://localhost:1420/road-conditions";
    historicalStormDataURLPrefix = "dev_";
} else if (process.env.REACT_APP_ENV === 'staging') {
    granularRatingsHost = `${apiHost}/impact`;
    rightrouteHost = `${apiHost}/rightroute`;
    forecastHost = `${apiHost}/weather/forecast`;
    impactTilesHost = "https://staging-tilingv2.weatheroptics.co";
    alertsHost = `${apiHost}/alerts`;
    eventsHost = `${apiHost}/events`;
    roadStatusHost = `${apiHost}/road-conditions`;
    historicalStormDataURLPrefix = "staging_";
} else {
    granularRatingsHost = `${apiHost}/impact`;
    rightrouteHost = `${apiHost}/rightroute`;
    forecastHost = `${apiHost}/weather/forecast`;
    impactTilesHost = "https://tilingv2.weatheroptics.co";
    alertsHost = `${apiHost}/alerts`;
    eventsHost = `${apiHost}/events`;
    roadStatusHost = `${apiHost}/road-conditions`;
    historicalStormDataURLPrefix = "";
}
const historicalStormDataURL = `https://storage.googleapis.com/historical_storm_cogs/${historicalStormDataURLPrefix}historical_storm_metadata.json?_=${Math.floor(new Date().getTime())}`;

// only change the Rails API host
const cachedAPIHost = localStorage.getItem('API_HOST');
if (cachedAPIHost) {
    apiHost = cachedAPIHost;
}

export const API_HOST = apiHost;
export const CABLE_HOST = apiHost.replace(/^http(s?)/, 'ws$1');
export const GRANULAR_RATINGS_HOST = granularRatingsHost;
export const RIGHTROUTE_HOST = rightrouteHost;
export const FORECAST_HOST = forecastHost;
export const ALERTS_HOST = alertsHost;
export const EVENTS_HOST = eventsHost;
export const ROAD_STATUS_HOST = roadStatusHost;
export const HISTORICAL_STORM_DATA_URL = historicalStormDataURL;
export const GA_ID = 'G-P4RL582G7W';

export const WO_STATIC_MAP_ENDPOINT = 'https://maps.weatheroptics.co/';
export const IMPACT_TILES_ENDPOINT = impactTilesHost; // append e.g. portal/below32

export const INFO_EMAIL = 'info@weatheroptics.co';

export const HERE_API_KEY = 'HAP3kj_dnYfaL7EQQC_3eoOZAsUpar6rgDimBSXwIX8';

export const bootstrapURLKeys = { key: "AIzaSyDPTSpOAhg0bZfcTnqcTY6kelFT89UtQGc", version: "3.58", libraries: ["geometry", "marker"] };

export const cardBackgroundColor = 'rgb(44, 52, 59)';
export const brightCardBackgroundColor = '#3F464D';
export const pageBackgroundColor = '#1D292F';
