import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { SetSelectedView, SetSelectedOverlay, DashboardSetSelectedMap, DashboardSetTileOpacity } from 'src/actions/DashboardView';
import { DashboardView } from 'src/components/Client/Dashboard/ImpactDetailView';
import { loadEventsIfNeeded, loadWeatherIfNeeded } from 'src/actions/Weather';
import { loadGovernmentalAlertsIfNeeded, loadRatingsIfNeeded } from 'src/actions/Ratings';
import { ImpactMapSection } from 'src/components/Client/Impact/sections';
import { MapType } from 'src/types/MapType';
import { CycloneData, EarthquakeData, FireData, LightningData, LocationData, StoreState, StormData, TrafficIncidentData, VolcanoData, WildfireData } from 'src/types';
import { ClearSaveCityError, SelectCity, createCity, deleteCity, geocodeCity, loadCity, loadRoute } from 'src/actions/SelectedCity';
import { FreightWavesDashboardPage } from 'src/components/CustomDashboards/FreightWaves';
import { DashboardOverlay } from 'src/components/Client/Dashboard';
import { ClearSelectedEvent, ImpactSetCyclonesVisible, ImpactSetEarthquakesVisible, ImpactSetFiresVisible, ImpactSetLightningVisible, ImpactSetStormsVisible, ImpactSetVolcanoesVisible, ImpactSetWildfiresVisible, SelectCyclone, SelectEarthquake, SelectFire, SelectLightning, SelectStorm, SelectVolcano, SelectWildfire } from 'src/actions/ImpactView';
import { initiateLoginWithData } from '../../../actions/User';
import { TrafficClearSelectedRoadCondition, TrafficSetSelectedTrafficIncident } from '../../../actions/TrafficView';

export function mapStateToProps(state: StoreState, _ownProps: RouteComponentProps<any>) {
    return {
        userData: state.user,
        selectedView: state.dashboardView.selectedView,
        selectedOverlay: state.dashboardView.selectedOverlay,
        selectedCityState: state.selectedCity,
        selectedCity: state.selectedCity.selectedCity,
        selectedCyclone: state.impactView.selectedCyclone,
        selectedEarthquake: state.impactView.selectedEarthquake,
        selectedLightning: state.impactView.selectedLightning,
        selectedStorm: state.impactView.selectedStorm,
        selectedVolcano: state.impactView.selectedVolcano,
        selectedFire: state.impactView.selectedFire,
        selectedWildfire: state.impactView.selectedWildfire,
        ratingsData: state.ratings.ratingsData.value,
        weatherData: state.weather.weatherData,
        tileOpacity: state.dashboardView.tileOpacity,
        blurbs: state.ratings.blurbs,
        cyclones: state.weather.cyclones,
        earthquakes: state.weather.earthquakes,
        lightning: state.weather.lightning,
        storms: state.weather.storms,
        volcanoes: state.weather.volcanoes,
        fires: state.weather.fires,
        wildfires: state.weather.wildfires,
        impactTilesets: state.ratings.impactTilesets?.value,
        weatherTilesets: state.ratings.weatherTilesets?.value,
        selectedTrafficIncident: state.trafficView.selectedTrafficIncident,
        governmentalAlerts: state.ratings.governmentalAlerts,

        isCyclonesVisible: state.impactView.isCyclonesVisible,
        isEarthquakesVisible: state.impactView.isEarthquakesVisible,
        isLightningVisible: state.impactView.isLightningVisible,
        isStormsVisible: state.impactView.isStormsVisible,
        isVolcanoesVisible: state.impactView.isVolcanoesVisible,
        isFiresVisible: state.impactView.isFiresVisible,
        isWildfiresVisible: state.impactView.isWildfiresVisible,
        isRoadStatusVisible: state.trafficView.isRoadStatusVisible,

        showDisruptionIndex: state.user.showDisruptionIndex,
        showWildfireIndices: state.user.showWildfireIndices
    };
}

export function mapDispatchToProps(dispatch: ThunkDispatch<StoreState, void, Action<any>>) {
    return {
        setSelectedView: (view: DashboardView) => dispatch(SetSelectedView({ selectedView: view })),
        setSelectedOverlay: (overlay: DashboardOverlay) => dispatch(SetSelectedOverlay({ selectedOverlay: overlay })),
        onCitySelected: (city: LocationData | undefined) => {
            dispatch(SelectCity({ city }));
            if (city) {
                if (city.needsGeocoding) {
                    dispatch(geocodeCity(city));
                } else {
                    dispatch(loadWeatherIfNeeded());
                    dispatch(loadRatingsIfNeeded());
                }
            }
        },
        onCitySaved: (city: LocationData) => {
            dispatch(createCity(city));
        },
        onCityUnsaved: (city: LocationData) => {
            dispatch(deleteCity(city));
        },
        onCitySaveErrorDismissed: (city: LocationData) => {
            dispatch(ClearSaveCityError({ city }));
        },
        onSetTileOpacity: (tileOpacity: number) => {
            dispatch(DashboardSetTileOpacity({ tileOpacity }));
        },
        onMapSelected: (selectedMapCategory: ImpactMapSection, selectedMapType: MapType) => {
            dispatch(DashboardSetSelectedMap({ selectedMapCategory, selectedMapType }));
            dispatch(loadWeatherIfNeeded());
            dispatch(loadRatingsIfNeeded());
        },
        onLoadEvents: () => {
            dispatch(loadEventsIfNeeded());
        },
        onCyclonesToggled: (visible: boolean) => {
            dispatch(ImpactSetCyclonesVisible({ visible }));
        },
        onEarthquakesToggled: (visible: boolean) => {
            dispatch(ImpactSetEarthquakesVisible({ visible }));
        },
        onLightningToggled: (visible: boolean) => {
            dispatch(ImpactSetLightningVisible({ visible }));
        },
        onStormsToggled: (visible: boolean) => {
            dispatch(ImpactSetStormsVisible({ visible }));
        },
        onVolcanoesToggled: (visible: boolean) => {
            dispatch(ImpactSetVolcanoesVisible({ visible }));
        },
        onFiresToggled: (visible: boolean) => {
            dispatch(ImpactSetFiresVisible({ visible }));
        },
        onWildfiresToggled: (visible: boolean) => {
            dispatch(ImpactSetWildfiresVisible({ visible }));
        },
        onCycloneSelected: (cyclone: CycloneData) => {
            dispatch(SelectCyclone({ cyclone }));
        },
        onEarthquakeSelected: (earthquake: EarthquakeData) => {
            dispatch(SelectEarthquake({ earthquake }));
        },
        onLightningSelected: (lightning: LightningData) => {
            dispatch(SelectLightning({ lightning }));
        },
        onStormSelected: (storm: StormData) => {
            dispatch(SelectStorm({ storm }));
        },
        onVolcanoSelected: (volcano: VolcanoData) => {
            dispatch(SelectVolcano({ volcano }));
        },
        onFireSelected: (fire: FireData) => {
            dispatch(SelectFire({ fire }));
        },
        onWildfireSelected: (wildfire: WildfireData) => {
            dispatch(SelectWildfire({ wildfire }));
        },
        onTrafficIncidentSelected: (trafficIncident?: TrafficIncidentData) => {
            dispatch(TrafficSetSelectedTrafficIncident({ trafficIncident }));
        },
        onClearSelectedEvent: () => {
            dispatch(ClearSelectedEvent());
        },
        onClearSelectedRoadCondition: () => {
            dispatch(TrafficClearSelectedRoadCondition());
        },

        loadLocation: (locationId: string) => dispatch(loadCity(locationId)),
        loadRoute: (routeId: string) => dispatch(loadRoute(routeId)),
        loadGovernmentalAlertsIfNeeded: () => dispatch(loadGovernmentalAlertsIfNeeded()),

        initiateLogin: (loginData: any) => dispatch(initiateLoginWithData(loginData)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FreightWavesDashboardPage));
