// from https://usehooks.com/useLocalStorage/

import { useState } from "react";

// Hook
export function useLocalStorage(key: string, initialValue: string | undefined) {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
        if (typeof window === "undefined") {
            return initialValue;
        }

        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item ? item : initialValue;
        } catch (error) {
            // If error also return initialValue
            console.log(error);
            return initialValue;
        }
    });

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: string | undefined | ((val: string | undefined) => string | undefined)) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            if (typeof window !== "undefined") {
                if (valueToStore === undefined) {
                    window.localStorage.removeItem(key);
                } else {
                    window.localStorage.setItem(key, valueToStore);
                }
                document.dispatchEvent(new Event('localStorageUpdated'));
            }
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    };

    return [storedValue, setValue] as const;
}

export function useLocalStorageBoolean(key: string, initialValue: boolean) {
    const [storedValue, setStoredValue] = useLocalStorage(key, initialValue.toString());
    const setValue = (value: boolean | (() => boolean)) => {
        const valueToStore = value instanceof Function ? value() : value;
        setStoredValue(valueToStore?.toString());
    };
    return [storedValue === 'true', setValue] as const;
}