import * as React from 'react';
import './index.css';
import {
    LocationData,
    HourRatingsData,
    UserState,
    WeatherData,
    RatingsDataWithLocationInfo,
    BlurbsByIndex,
    BasicLatLng,
    ImpactTileset,
    ImpactTilesetsByIndex,
    SelectedCityState,
    ImpactRunTimes,
    StormData,
    WeatherTilesetsByIndex,
    WeatherRunTimes,
    VolcanoData,
    EarthquakeData,
    CycloneData,
    FireData,
    WildfireData,
    LightningData,
    isValidUserForFeature,
    ClientId,
} from '../../../types';
import {
    ImpactMapType,
    MapType,
    MaskType,
} from '../../../types/MapType';
import { isImpactKey } from '../../../types/RatingKey';
import { getClientImpactSections, ImpactMapSection } from './sections';
import { MapControlsComponent } from '../../shared/MapControlsComponent';
import { LegendComponent } from './LegendComponent';
import { MapOptionsComponent } from './MapOptionsComponent';
import { ImpactCalloutView } from './ImpactCalloutView';
import { TimeSeriesGraphView } from './TimeSeriesGraphView';
import { LocationSelectionComponent } from '../../shared/LocationSelectionComponent';
import TimeAgo from 'javascript-time-ago';
import * as H from 'history';
import { ALL_RATING_KEYS } from '../../../types/RatingKey';
import { cardBackgroundColor } from "../../../constants";
import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';
import moment from 'moment';
import { snakeToCamelCase } from 'src/types/unmarshal';
import { GridExpandMoreIcon } from '@mui/x-data-grid-pro';
import { EventDescriptionComponent } from './EventDescriptionComponent';
import { RouteAndMarkerMap, defaultRouteAndMarkerMapProps } from '../../shared/RouteAndMarkerMap';
import { Spacer } from '../../Public/PricingCalculatorEmbed';

export class TilesetUtils {
    static getPreviousTileset(tilesets: ImpactTileset[] | undefined, desiredTileTime: Date) {
        if (!tilesets) {
            return undefined;
        }
        const index = tilesets.map(t => t.time.getTime()).indexOf(desiredTileTime.getTime());
        if (index === -1) {
            return undefined;
        }
        const nextIndex = (index - 1 + tilesets.length) % tilesets.length;
        return tilesets[nextIndex];
    }

    static getTileset(
        tilesets: ImpactTileset[] | undefined,
        desiredTileTime: Date,
        options: { allowNearestMatch: boolean } = { allowNearestMatch: false },
    ) {
        if (!tilesets) {
            return undefined;
        }
        // look for an exact match
        const exactIndex = tilesets.findIndex(t => t.time.getTime() === desiredTileTime.getTime());
        if (exactIndex >= 0) {
            return tilesets[exactIndex];
        }
        if (!options.allowNearestMatch) {
            return undefined;
        }

        // after writing all of this allowNearestMatch code, I realized that this is not relevant
        // when switching from nowcast to extended or vice versa because they are supposed to be
        // disjoint time ranges, so all it does is just take you to the end of nowcast or start of
        // extended when you switch, oh well
        let nearestIndex = tilesets.findIndex(t => t.time.getTime() > desiredTileTime.getTime());
        // -1 here means that the last tileset time was not larger than than the desired tile time
        // but it would still be the nearest one so we report the last one
        if (nearestIndex === -1) {
            nearestIndex = tilesets.length - 1;
        } else if (nearestIndex > 0) {
            // since we match when time > desired time, we'll (e.g.) match to 7:00 for 6:00, 6:15, 6:30, 6:45
            // so we subtract one to go the 6:00 index which would encompass those subhourly times
            nearestIndex -= 1;
        }
        return tilesets[nearestIndex];
    }

    static getNextTileset(tilesets: ImpactTileset[] | undefined, desiredTileTime: Date) {
        if (!tilesets) {
            return undefined;
        }
        const index = tilesets.map(t => t.time.getTime()).indexOf(desiredTileTime.getTime());
        if (index === -1) {
            return undefined;
        }
        const nextIndex = (index + 1) % tilesets.length;
        return tilesets[nextIndex];
    }
}

// Impact page - displays our proprietary impact indexes.

interface Props {
    location: H.Location<H.LocationState>;
    history: H.History;

    showDisruptionIndex: boolean;
    showWildfireIndices: boolean;

    // general info needed to display stuff
    userData?: UserState;
    selectedCityState: SelectedCityState;
    weatherData?: WeatherData;
    cyclones?: CycloneData[];
    earthquakes?: EarthquakeData[];
    lightning?: LightningData[];
    storms?: StormData[];
    volcanoes?: VolcanoData[];
    fires?: FireData[];
    wildfires?: WildfireData[];
    ratingsData?: RatingsDataWithLocationInfo;
    ratingsFetchError?: Error;
    blurbs: BlurbsByIndex;
    impactTilesets?: ImpactTilesetsByIndex;
    impactRunTimes?: ImpactRunTimes;
    impactTilesetsCreatedAt?: ImpactRunTimes;
    weatherTilesets?: WeatherTilesetsByIndex;
    weatherRunTimes?: WeatherRunTimes;
    weatherTilesetsCreatedAt?: WeatherRunTimes;
    isGeocoding: boolean;
    isFetching: boolean;

    tileOpacity: number;
    currentTileset?: ImpactTileset;

    center?: BasicLatLng;
    zoomLevel: number;

    selectedMapCategorySlug: string;
    selectedMapType: ImpactMapType;
    selectedCyclone: CycloneData;
    selectedEarthquake: EarthquakeData;
    selectedLightning: LightningData;
    selectedStorm: StormData;
    selectedVolcano: VolcanoData;
    selectedFire: FireData;
    selectedWildfire: WildfireData;

    // specifies the animation parameter values for the map controls
    speedIncrements: number[];
    baseAnimationDelay: number;

    selectedSpeedMultiplierIndex: number;
    animationDelay: number;
    paused: boolean;

    mapOptionsCollapsed: boolean;
    selectedGraphView: string;

    isCyclonesVisible: boolean;
    isEarthquakesVisible: boolean;
    isLightningVisible: boolean;
    isStormsVisible: boolean;
    isVolcanoesVisible: boolean;
    isFiresVisible: boolean;
    isWildfiresVisible: boolean;

    previousTimeIndex?: number;
    prefetchTimeIndex: number;

    // callback for when a city is selected/created from the geocoder
    onCitySelected: (city: LocationData | undefined) => void;
    onCitySaved: (city: LocationData) => void;
    onCityUnsaved: (city: LocationData) => void;
    onCitySaveErrorDismissed: (city: LocationData) => void;

    onSetTileOpacity: (tileOpacity: number) => void;
    onSetCurrentTileset: (tileset: ImpactTileset) => void;

    onCycloneSelected: (cyclone: CycloneData | undefined) => void;
    onEarthquakeSelected: (earthquake: EarthquakeData | undefined) => void;
    onLightningSelected: (lightning: LightningData | undefined) => void;
    onStormSelected: (storm: StormData | undefined) => void;
    onVolcanoSelected: (volcano: VolcanoData | undefined) => void;
    onFireSelected: (fire: FireData | undefined) => void;
    onWildfireSelected: (wildfire: WildfireData | undefined) => void;
    onClearSelectedEvent: () => void;

    onCenterChanged: (center: BasicLatLng) => void;
    onZoomLevelChanged: (zoomLevel: number) => void;

    onPauseToggled: (paused: boolean) => void;
    onMapOptionsToggled: (collapsed: boolean) => void;
    onAnimationSpeedChanged: (selectedSpeedMultiplierIndex: number, animationDelay: number) => void;
    onMapSelected: (selectedMapCategory: ImpactMapSection, selectedMapType: MapType) => void;
    onLoadEvents: () => void;
    onCyclonesToggled: (show: boolean) => void;
    onEarthquakesToggled: (show: boolean) => void;
    onLightningToggled: (show: boolean) => void;
    onStormsToggled: (show: boolean) => void;
    onVolcanoesToggled: (show: boolean) => void;
    onFiresToggled: (show: boolean) => void;
    onWildfiresToggled: (show: boolean) => void;
    openedPage: (page: string) => void;

    loadLocation: (locationId: string) => void;
}

interface ImpactPageState {
    // store map of user's last visited map per section
    lastVisitedMapMap: Record<string, MapType>;
    processedLocationParams: boolean;
    processedTileMapParam: boolean;
    processedTileTimeParam: boolean;

    visibleTileset?: ImpactTileset;
    desiredTileset?: ImpactTileset;

    showModelForecastTracksForCycloneId?: string;

    showCriticalEventsUpsell: boolean;
}

// Function to get full time zone name from abbreviation
export function getFullTimeZoneName(abbreviation: string): string | undefined {
    // a non exhaustive list of abbreviations that don't have the expected abbreviation
    // in moment-timezone e.g. Atlantic/Cape_Verde returns -01 instead of CVT
    const missingTimezoneAbbreviations = {
        'CVT': 'Atlantic/Cape_Verde',
    };
    if (abbreviation in missingTimezoneAbbreviations) {
        return missingTimezoneAbbreviations[abbreviation];
    }
    const matchingTimezone = moment.tz.names().find(timezone => {
        const zoneAbbr = moment.tz.zone(timezone)?.abbr(moment().toDate().getTime());
        return zoneAbbr === abbreviation;
    });
    return matchingTimezone;
}

class ClientImpactPageInner extends React.Component {
    props: Props;

    state: ImpactPageState;

    timer?: NodeJS.Timeout;

    hasFiredInitialRatingsFetch = false;

    constructor(props: Props) {
        super(props);

        const hasNoSearchString = window.location.search.length === 0;
        this.state = {
            lastVisitedMapMap: {},
            // if we have no search string, then we have "processed" it
            processedLocationParams: hasNoSearchString,
            processedTileMapParam: hasNoSearchString,
            processedTileTimeParam: hasNoSearchString,

            visibleTileset: undefined,
            desiredTileset: undefined,

            showCriticalEventsUpsell: false,
        };
    }

    componentDidMount(): void {
        this.props.onLoadEvents();
        this.props.openedPage('impact');
        this.setState({
            ...this.state,
            desiredTileset: this.props.currentTileset
        });
    }

    componentWillUnmount(): void {
        super.componentWillUnmount?.();
        clearTimeout(this.timer);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<ImpactPageState>, snapshot?: any): void {
        super.componentDidUpdate?.(prevProps, prevState, snapshot);
        const stateChanges = {};

        // update URL
        let mapCategories = getClientImpactSections(this.props.showDisruptionIndex, this.props.showWildfireIndices);
        let matchedMap: ImpactMapType = this.selectedMapType() as ImpactMapType;
        let path = window.location.pathname;
        let pathPieces = path.split('/');
        breakout: if (pathPieces.length === 5 && this.state.processedTileMapParam === false) {
            path = `/client/impact/${pathPieces[3]}/${pathPieces[4]}`;
            if (pathPieces[3].includes('impact')) {
                if (this.props.impactTilesets === undefined) break breakout;
                const impactCategory = mapCategories.find(category => category.slug === 'impact-indices');
                matchedMap = impactCategory?.mapTypes?.find(map => map.slug === pathPieces[4]) as ImpactMapType;
                if (impactCategory && matchedMap) {
                    this.props.onMapSelected(impactCategory, matchedMap);
                }
            }
            if (pathPieces[3].includes('weather')) {
                if (this.props.weatherTilesets === undefined) break breakout;
                const weatherCategory = mapCategories.find(category => category.slug === 'weather-indices');
                matchedMap = weatherCategory?.mapTypes?.find(map => map.slug === pathPieces[4]) as ImpactMapType;
                if (weatherCategory && matchedMap) {
                    this.props.onMapSelected(weatherCategory, matchedMap);
                }
            }
            stateChanges['processedTileMapParam'] = true;
        } else {
            path = `/client/impact/${this.props.selectedMapCategorySlug}/${this.props.selectedMapType.slug}`;
        }

        // toggle wildfire perimeter visibility
        if (matchedMap.slug !== prevProps.selectedMapType.slug) {
            if (matchedMap.slug.indexOf('wildfire') !== -1) {
                this.props.isWildfiresVisible === false && this.props.onWildfiresToggled(true);
            } else {
                this.props.isWildfiresVisible === true && this.props.onWildfiresToggled(false);
            }
        }

        breakout: if (this.state.processedLocationParams === false && this.props.userData) {
            const urlParams = new URLSearchParams(window.location.search);

            const locationId = urlParams.get('location_id');
            if (locationId !== null) {
                const linkedLocation = this.props.userData.cities.find(city => city.id?.toString() === locationId);
                console.log('processedLocationParams', 'linkedLocation', linkedLocation, 'loading?', this.props.userData.citiesMetadata.loading);
                if (!linkedLocation && this.props.userData.citiesMetadata.loading) {
                    console.log('processedLocationParams', 'loading location id', locationId);
                    this.props.loadLocation(locationId);
                    break breakout;
                }
                if (linkedLocation !== undefined) {
                    // noting some current drawbacks:
                    // - the map won't show the pin of the selected city until all cities are loaded
                    this.props.onCitySelected(linkedLocation);
                }
            } else {
                const latitude = urlParams.get('latitude');
                const longitude = urlParams.get('longitude');
                if (latitude !== null && longitude !== null) {
                    const lat: number = parseFloat(latitude);
                    const lng: number = parseFloat(longitude);
                    if (!isNaN(lat) && !isNaN(lng)) {
                        // fake a map click to select passed in location
                        this.calloutViewCoordinateSelected({ lat: () => lat, lng: () => lng } as google.maps.LatLng);
                    }
                }
            }

            stateChanges['processedLocationParams'] = true;
        }

        if (this.state.processedTileTimeParam === false && this.props.impactTilesets && this.props.weatherTilesets) {
            const urlParams = new URLSearchParams(window.location.search);

            const mapTime = urlParams.get('time');
            if (mapTime !== null) {
                const time = new Date(mapTime!);
                const dateTileset = TilesetUtils.getTileset(this.getTilesets(), time);
                stateChanges['desiredTileset'] = dateTileset;
            }

            stateChanges['processedTileTimeParam'] = true;
        }

        if (this.state.processedLocationParams === true && this.state.processedTileMapParam === true && this.state.processedTileTimeParam === true) {
            const urlParams = new URLSearchParams();

            if (this.props.selectedCityState.selectedCity?.id !== undefined) {
                urlParams.set('location_id', `${this.props.selectedCityState.selectedCity.id}`);
            }

            let currentMapType: ImpactMapType | undefined = this.selectedMapType() as ImpactMapType;
            if (currentMapType === undefined) {
                const impactCategory = mapCategories.find(category => category.slug === 'impact-indices');
                const weatherCategory = mapCategories.find(category => category.slug === 'weather-indices');
                currentMapType = this.selectedMapCategory().slug === 'impact-indices' ? impactCategory?.mapTypes[0] as ImpactMapType : weatherCategory?.mapTypes[0] as ImpactMapType;
            }

            if (currentMapType?.ratingKey !== 'wildfire_spread' && this.props.paused === true) {
                const mapTime: Date | undefined = this.state.visibleTileset?.time;
                if (mapTime !== undefined) urlParams.set('time', mapTime.toISOString());
            }

            const oldParams = window.location.toString().split('?')[1];
            if (urlParams.toString() !== '' && oldParams !== urlParams.toString()) {
                path += `?${urlParams.toString()}`;
            }
        }

        if (this.props.location.pathname !== path) {
            this.props.history.push(path);
        }

        const tilesets = this.getTilesets() || [];
        if (this.state.desiredTileset === undefined && tilesets[0] !== undefined) {
            stateChanges['desiredTileset'] = tilesets[0];
        } else if (this.state.desiredTileset && tilesets.findIndex(x => this.state.desiredTileset?.id === x.id) === -1) {
            console.log('setting new desired tileset because the currently desired one is no longer in the tileset list');
            const matchingDesiredTileset = tilesets.find(x => x.time.getTime() === this.state.desiredTileset?.time.getTime());
            stateChanges['desiredTileset'] = matchingDesiredTileset ?? tilesets[0];
        }

        // only update stae once at the end if state needs to be updated so the inividual changes dont get overwritten by later statue updated
        if (Object.keys(stateChanges).length > 0) {
            this.setState({
                ...this.state,
                ...stateChanges
            });
        }
    }

    selectedMapCategory(): ImpactMapSection {
        let mapCategories = getClientImpactSections(this.props.showDisruptionIndex, this.props.showWildfireIndices);
        return mapCategories.find(category => category.slug === this.props.selectedMapCategorySlug) ?? mapCategories[0];
    }

    selectedMapType() {
        let mapTypes = this.selectedMapCategory().mapTypes;
        return mapTypes.find(mapType => {
            let matchesSlug = mapType.slug === this.props.selectedMapType.slug;
            return matchesSlug;
        }) ?? mapTypes[0];
    }

    goToPreviousTimeOffset() {
        if (!this.state.desiredTileset) {
            return;
        }

        this.setState({
            ...this.state,
            desiredTileset: TilesetUtils.getPreviousTileset(this.getTilesets(), this.state.desiredTileset.time),
        });
    }

    // increments our time offset, kicking off a new map load.
    goToNextTimeOffset() {
        if (!this.state.desiredTileset) {
            return;
        }

        this.setState({
            ...this.state,
            desiredTileset: TilesetUtils.getNextTileset(this.getTilesets(), this.state.desiredTileset.time),
        });
    }

    resume() {
        clearTimeout(this.timer);

        this.timer = setTimeout(() => this.goToNextTimeOffset(), this.props.animationDelay);

        this.props.onPauseToggled(false);
    }

    goToDate(date: Date) {
        this.pause();
        this.setState({
            ...this.state,
            desiredTileset: TilesetUtils.getTileset(this.getTilesets(), date),
        });
    }

    rewind() {
        this.pause();
        this.goToPreviousTimeOffset();
    }

    skipToFirst() {
        const tilesets = this.getTilesets();
        if (tilesets) {
            this.pause();
            this.setState({
                ...this.state,
                desiredTileset: tilesets[0],
            });
        }
    }

    skipToLast() {
        const tilesets = this.getTilesets();
        if (tilesets) {
            this.pause();
            this.setState({
                ...this.state,
                desiredTileset: tilesets[tilesets.length - 1],
            });
        }
    }

    fastForward() {
        this.pause();
        this.goToNextTimeOffset();
    }

    pause() {
        clearTimeout(this.timer);
        this.props.onPauseToggled(true);
    }

    cycleSpeedIncrement() {
        let speedMultiplierIndex = (this.props.selectedSpeedMultiplierIndex + 1) % this.props.speedIncrements.length;
        let speedMultiplier = this.props.speedIncrements[speedMultiplierIndex];
        let animationDelay = this.props.baseAnimationDelay / speedMultiplier;

        this.props.onAnimationSpeedChanged(speedMultiplierIndex, animationDelay);
    }

    calloutViewCoordinateSelected(coord: google.maps.LatLng) {
        let city: LocationData = {
            id: undefined,
            zip: '00000',
            name: '',
            latitude: coord.lat(),
            longitude: coord.lng(),
            needsGeocoding: true,
            impactSummaries: []
        };

        this.props.onCitySelected(city);
    }

    /**
     * @param selectedMapType the ImpactMapType that has been selected by the user e.g. disruption or temperature
     * @param context the context that the tileset data is being used for. we use this for the special case of wildfire spread
     * because for visualizing ('map' context), we want to show one tileset to represent the next 24 hours of spread. however,
     * in the graph ('graph' context), we want the next 24 hours of data to show that the visual is representing a 24 hour
     * forecast
     * @returns a list of ImpactTileset for the current map and context
     */
    getTilesets(selectedMapType: MapType = this.selectedMapType(), context: string = 'map'): ImpactTileset[] | undefined {
        let mapType = selectedMapType as ImpactMapType;
        let tilesets: ImpactTileset[] | undefined = this.props.impactTilesets && this.props.impactTilesets[mapType.ratingKey];
        if (tilesets === undefined) {
            tilesets = this.props.weatherTilesets && this.props.weatherTilesets[mapType.ratingKey];
        }
        if (!tilesets) {
            return undefined;
        }

        const currentTime = new Date().getTime();
        const startTime = Math.floor(currentTime / 3600000) * 3600000;
        let forecastHours = 168;
        if (selectedMapType.ratingKey === 'wildfire_spread') {
            forecastHours = context === 'map' ? 1 : 24;
        } else if (selectedMapType.ratingKey === 'wildfire_conditions') {
            forecastHours = 144;
        }

        const endTimeExclusive = startTime + forecastHours * 3600000;
        // chop off any times from the model run that are before now or later than endTimeExclusive hour from now
        return tilesets.filter((tileset: ImpactTileset) => tileset.time.getTime() >= startTime && tileset.time.getTime() < endTimeExclusive);
    }

    render() {
        const { userData, selectedCityState, ratingsData, weatherData, tileOpacity, onSetTileOpacity } = this.props;

        if (
            userData === undefined ||
            userData.portalToken === undefined ||
            this.props.impactTilesets === undefined ||
            this.props.weatherTilesets === undefined ||
            this.props.impactRunTimes === undefined
        ) {
            return <div />;
        }

        const { selectedCity } = selectedCityState;

        let onMapSelected = (mapType: MapType) => {
            let lastVisitedMapMap = { ...this.state.lastVisitedMapMap };
            lastVisitedMapMap[this.selectedMapCategory().title] = mapType;

            // try to keep same time index
            const tilesets = this.getTilesets(mapType);
            let newDesiredTileset: ImpactTileset | undefined = undefined;
            if (tilesets && this.state.desiredTileset) {
                newDesiredTileset = TilesetUtils.getTileset(tilesets, this.state.desiredTileset.time);
            }

            this.setState({
                ...this.state,
                lastVisitedMapMap,
                desiredTileset: newDesiredTileset ?? tilesets?.[0],
            });

            this.props.onMapSelected(this.selectedMapCategory(), mapType);
        };

        let onMapCategorySelected = (category: ImpactMapSection) => {
            let mapType = this.state.lastVisitedMapMap[category.title] || category.mapTypes[0];

            this.props.onMapSelected(category, mapType);
        };

        const tilesets = this.getTilesets();

        const displayedTime: Date | undefined = this.state.desiredTileset?.time;

        let selectedMapType = this.selectedMapType();

        let hourRatings: HourRatingsData | undefined;
        if (this.state.desiredTileset) {
            let date = this.state.desiredTileset.time;
            if (ratingsData !== undefined && isImpactKey(selectedMapType.ratingKey)) {
                hourRatings = ratingsData[selectedMapType.ratingKey].find((hour: HourRatingsData) => hour.time.getTime() === date.getTime());
            } else if (weatherData !== undefined) {
                const weatherValue = weatherData.hourly.find((hour) => hour.time.getTime() === date.getTime());
                if (weatherValue) {
                    hourRatings = { time: weatherValue['time'], value: weatherValue[snakeToCamelCase(selectedMapType.ratingKey)] };
                }
            }
        }

        let mapType = selectedMapType as ImpactMapType;
        let indexKey = mapType.ratingKey;
        let indexName = mapType.title;
        let blurbs = this.props.blurbs[mapType.ratingKey] ?? [];

        let currentStorms = this.props.storms || [];
        currentStorms = currentStorms.filter(storm => displayedTime && storm.startTime <= displayedTime && storm.endTime >= displayedTime);

        const showEvents = userData?.showEventsInPortal ?? false;
        // wildfires has a special case where we want to show it over the wildfire indices
        const showWildfires = (showEvents && this.props.isWildfiresVisible) || selectedMapType.ratingKey.includes('wildfire') === true;
        const mapElement = (
            <RouteAndMarkerMap
                {...defaultRouteAndMarkerMapProps}

                portalToken={userData.portalToken}
                initialCenter={this.props.center}
                // initially show the whole conus
                initialBounds={{ sw: { lat: 24.396308, lng: -125.0 }, ne: { lat: 49.384358, lng: -66.93457 } }}
                zoomLevel={this.props.zoomLevel}

                maskType={MaskType.LabelsAndWater}

                desiredTileLayer={this.state.desiredTileset}
                tileOpacity={tileOpacity}
                onTileLayerLoaded={(visibleTileset) => {
                    console.log('onTileLayerLoaded', visibleTileset);
                    this.props.onSetCurrentTileset(visibleTileset as ImpactTileset);
                    this.setState({
                        ...this.state,
                        visibleTileset,
                    });

                    if (!this.props.paused && visibleTileset?.id === this.state.desiredTileset?.id) {
                        clearTimeout(this.timer);
                        this.timer = setTimeout(() => this.goToNextTimeOffset(), this.props.animationDelay);
                    }
                }}

                isLocationsVisible={true}
                citiesLoading={userData.citiesMetadata.loading}
                savedCities={userData.cities}
                selectedCity={selectedCity}
                onCitySelected={(city) => this.props.onCitySelected(city)}

                showEventMarkersAboveLabels={true}

                isLightningVisible={showEvents && this.props.isLightningVisible}
                lightning={this.props.lightning || []}
                selectedLightning={this.props.selectedLightning}
                onLightningSelected={(lightning) => this.props.onLightningSelected(lightning)}

                isCyclonesVisible={showEvents && this.props.isCyclonesVisible}
                cyclones={this.props.cyclones || []}
                showModelForecastTracksForCycloneId={this.state.showModelForecastTracksForCycloneId}
                selectedCyclone={this.props.selectedCyclone}
                onCycloneSelected={(cyclone) => this.props.onCycloneSelected(cyclone)}

                isEarthquakesVisible={showEvents && this.props.isEarthquakesVisible}
                earthquakes={this.props.earthquakes || []}
                selectedEarthquake={this.props.selectedEarthquake}
                onEarthquakeSelected={(earthquake) => this.props.onEarthquakeSelected(earthquake)}

                isStormsVisible={showEvents && this.props.isStormsVisible}
                storms={currentStorms}
                selectedStorm={this.props.selectedStorm}
                onStormSelected={(storm) => this.props.onStormSelected(storm)}

                isVolcanoesVisible={showEvents && this.props.isVolcanoesVisible}
                volcanoes={this.props.volcanoes || []}
                selectedVolcano={this.props.selectedVolcano}
                onVolcanoSelected={(volcano) => this.props.onVolcanoSelected(volcano)}

                isFiresVisible={showEvents && this.props.isFiresVisible}
                fires={this.props.fires || []}
                selectedFire={this.props.selectedFire}
                onFireSelected={(fire) => this.props.onFireSelected(fire)}

                isWildfiresVisible={showWildfires}
                wildfires={this.props.wildfires || []}
                selectedWildfire={this.props.selectedWildfire}
                onWildfireSelected={(wildfire) => this.props.onWildfireSelected(wildfire)}

                onMapClicked={(coord) => this.calloutViewCoordinateSelected(coord)}
                onCenterChanged={(center) => this.props.onCenterChanged(center)}
                onZoomLevelChanged={(zoomLevel: number) => this.props.onZoomLevelChanged(zoomLevel)}
            >
                {selectedCityState.selectedCity && <ImpactCalloutView
                    selectedCityState={selectedCityState}
                    paused={this.props.paused}
                    isFetching={this.props.isFetching}
                    ratingsFetchError={this.props.ratingsFetchError}
                    weatherData={weatherData}
                    indexName={indexName}
                    indexKey={indexKey}
                    hour={hourRatings}

                    lat={selectedCityState.selectedCity.latitude}
                    lng={selectedCityState.selectedCity.longitude}

                    onClose={() => this.props.onCitySelected(undefined)}
                    saveLocationTapped={(city) => this.props.onCitySaved(city)}
                    unsaveLocationTapped={(city) => this.props.onCityUnsaved(city)}
                    onCitySaveErrorDismissed={(city) => this.props.onCitySaveErrorDismissed(city)}
                />}
            </RouteAndMarkerMap>
        );
        let mapControls: JSX.Element | undefined = undefined;
        if (tilesets && tilesets.length > 1) {
            const visibleTileset = this.state.visibleTileset;
            const desiredTileset = this.state.desiredTileset;
            const speedIncrement = this.props.speedIncrements[this.props.selectedSpeedMultiplierIndex];
            mapControls = (
                <MapControlsComponent
                    paused={this.props.paused}
                    speedIncrement={speedIncrement}
                    times={tilesets.map(x => x.time)}
                    displayedTime={displayedTime}
                    loading={visibleTileset?.id !== desiredTileset?.id}

                    onPause={() => this.pause()}
                    onResume={() => this.resume()}
                    onFastForward={() => this.fastForward()}
                    onRewind={() => this.rewind()}
                    onSkipToFirst={() => this.skipToFirst()}
                    onSkipToLast={() => this.skipToLast()}
                    onCycleSpeedIncrement={() => this.cycleSpeedIncrement()}
                    setSelectedDate={(date: Date) => this.goToDate(date)}
                />
            );
        }

        let mapCategories = getClientImpactSections(this.props.showDisruptionIndex, this.props.showWildfireIndices);
        let maps = this.selectedMapCategory().mapTypes;

        const graphTileSets = this.getTilesets(this.selectedMapType(), 'graph');
        let timeSeriesGraphsComponent: JSX.Element | undefined;
        if (selectedCity && ratingsData && graphTileSets) {
            const filteredRatingsData = Object.assign({}, ratingsData);
            const startTime = graphTileSets[0].time.getTime();
            const endTimeInclusive = graphTileSets[graphTileSets.length - 1].time.getTime();
            for (const key of ALL_RATING_KEYS) {
                filteredRatingsData[key] = filteredRatingsData[key].filter((obj: any) => obj.time.getTime() >= startTime && obj.time.getTime() <= endTimeInclusive);
            }
            let filteredWeatherData = this.props.weatherData?.hourly || [];
            filteredWeatherData = filteredWeatherData.filter((obj: any) => obj.time.getTime() >= startTime && obj.time.getTime() <= endTimeInclusive);

            timeSeriesGraphsComponent = (
                <div className={"TimeSeriesGraphsContainer"}>
                    <TimeSeriesGraphView
                        selectedCityState={selectedCityState}
                        selectedGraphView={this.props.selectedGraphView}
                        onValueClicked={date => this.goToDate(date)}

                        selectedWeatherProperty={selectedMapType.ratingKey}
                        onWeatherPropertyChanged={ratingKey => {
                            const weatherCategory = mapCategories.find(category => category.slug === 'weather-indices');
                            const matchedMap = weatherCategory?.mapTypes?.find(map => map.ratingKey === ratingKey);
                            if (weatherCategory && matchedMap) {
                                if (this.getTilesets(matchedMap) !== undefined) {
                                    this.props.onMapSelected(weatherCategory, matchedMap);
                                }
                            }
                        }}
                        weatherConditions={filteredWeatherData}

                        showDisruptionIndex={this.props.showDisruptionIndex}
                        showWildfireIndices={this.props.showWildfireIndices}
                        ratings={filteredRatingsData}
                        timezone={filteredRatingsData.timezone}
                        blurbs={this.props.blurbs}
                        selectedRatingKey={selectedMapType.ratingKey}
                        onRatingKeySelected={ratingKey => {
                            const impactCategory = mapCategories.find(category => category.slug === 'impact-indices');
                            const matchedMap = impactCategory?.mapTypes?.find(map => map.ratingKey === ratingKey);
                            if (impactCategory && matchedMap) {
                                const tilesets = this.getTilesets(matchedMap);
                                if (tilesets !== undefined) {
                                    this.props.onMapSelected(impactCategory, matchedMap);
                                    if (ratingKey === 'wildfire_spread') {
                                        this.setState({
                                            ...this.state,
                                            desiredTileset: tilesets[0],
                                        });
                                    }
                                }
                            }
                        }}
                    />
                </div>
            );
        }

        const legendComponent = (
            <LegendComponent
                userData={userData}
                ratingKey={selectedMapType.ratingKey}
                blurbs={blurbs}
                skipFirstBlurb={true}
                embedValues={isImpactKey(selectedMapType.ratingKey)}
                tileOpacity={tileOpacity}
                setTileOpacity={(tileOpacity) => onSetTileOpacity(tileOpacity)}
            />
        );

        const locationSelectionComponent = (
            <div className={"LocationSelectionComponent-Container"}>
                <LocationSelectionComponent
                    user={userData}
                    savedCities={userData.cities}
                    selectedCity={selectedCity}
                    onCitySelected={city => this.props.onCitySelected(city)}
                    allowSelectingLocations
                />
            </div>
        );

        const eventDescriptionComponent = (
            <div>
                <EventDescriptionComponent
                    allowClose={true}

                    selectedCyclone={this.props.selectedCyclone}
                    showModelForecastTracksForCycloneId={this.state.showModelForecastTracksForCycloneId}
                    selectedEarthquake={this.props.selectedEarthquake}
                    selectedLightning={this.props.selectedLightning}
                    selectedStorm={this.props.selectedStorm}
                    selectedVolcano={this.props.selectedVolcano}
                    selectedFire={this.props.selectedFire}
                    selectedWildfire={this.props.selectedWildfire}
                    selectedRoadStatus={undefined}

                    isCyclonesVisible={showEvents && this.props.isCyclonesVisible}
                    isEarthquakesVisible={showEvents && this.props.isEarthquakesVisible}
                    isLightningVisible={showEvents && this.props.isLightningVisible}
                    isStormsVisible={showEvents && this.props.isStormsVisible}
                    isVolcanoesVisible={showEvents && this.props.isVolcanoesVisible}
                    isFiresVisible={showEvents && this.props.isFiresVisible}
                    isWildfiresVisible={showWildfires && this.props.isWildfiresVisible}
                    isRoadStatusVisible={false}
                    // add these to impact later?
                    isPowerOutagesVisible={false}
                    isStormReportsVisible={false}


                    onShowModelForecastTracksForCycloneIdSelected={(cycloneId) => this.setState({
                        ...this.state,
                        showModelForecastTracksForCycloneId: cycloneId,
                    })}
                    onClearSelectedEvent={() => this.props.onClearSelectedEvent()}
                />
            </div>
        );

        let runDate = undefined;
        if (isImpactKey(selectedMapType.ratingKey)) {
            runDate = this.props.impactTilesetsCreatedAt?.[selectedMapType.ratingKey];
            // fallback to the impactRunTimes if no value found for impactTilesetsCreatedAt
            if (runDate === undefined || isNaN(runDate.getTime())) {
                runDate = this.props.impactRunTimes[selectedMapType.ratingKey];
            }
        } else {
            runDate = this.props.weatherTilesetsCreatedAt?.[selectedMapType.ratingKey];
            // fallback to the impactRunTimes if no value found for impactTilesetsCreatedAt
            if (runDate === undefined || isNaN(runDate.getTime())) {
                runDate = this.props.weatherRunTimes && this.props.weatherRunTimes[selectedMapType.ratingKey];
            }
        }

        const timeAgo = new TimeAgo('en-US');
        const timeAgoString = timeAgo.format(runDate);

        const updateDate = new Date(runDate);
        // rough cadence is hourly updates so we add one hour
        updateDate.setHours(updateDate.getHours() + 1);
        const updateString = updateDate.toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' });

        const modelRunComponent = (
            <div className={'ModelRunComponent'} style={{ backgroundColor: cardBackgroundColor }}>
                <div className={'header'}>This data is from about {timeAgoString}</div>
                <div className={'body'}>Next Update: ~{updateString}</div>
            </div>
        );

        let showLayersComponent: JSX.Element | undefined = undefined;
        if (showEvents) {
            showLayersComponent = (
                <div className={'ShowLayersComponent'} style={{ backgroundColor: cardBackgroundColor, padding: '11px' }}>
                    <Accordion style={{ backgroundColor: cardBackgroundColor }}>
                        <AccordionSummary expandIcon={<GridExpandMoreIcon />} style={{ backgroundColor: cardBackgroundColor }}>
                            <Typography>Weather Events of Interest</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <FormGroup>
                                <FormControlLabel className={'switch'} control={<Switch checked={this.props.isCyclonesVisible} onChange={event => {
                                    this.props.onCyclonesToggled(event.target.checked);
                                    // when disabling cyclones, we want to disable any model forecast tracks
                                    if (!event.target.checked) {
                                        this.setState({
                                            ...this.state,
                                            showModelForecastTracksForCycloneId: undefined,
                                        });
                                    }
                                }} />} label="Cyclones" labelPlacement="start" />
                                <FormControlLabel className={'switch'} control={<Switch checked={this.props.isEarthquakesVisible} onChange={event => this.props.onEarthquakesToggled(event.target.checked)} />} label="Earthquakes" labelPlacement="start" />
                                <FormControlLabel className={'switch'} control={<Switch checked={this.props.isLightningVisible} onChange={event => this.props.onLightningToggled(event.target.checked)} />} label="Lightning" labelPlacement="start" />
                                <FormControlLabel className={'switch'} control={<Switch checked={this.props.isStormsVisible} onChange={event => this.props.onStormsToggled(event.target.checked)} />} label="Storms" labelPlacement="start" />
                                <FormControlLabel className={'switch'} control={<Switch checked={this.props.isVolcanoesVisible} onChange={event => this.props.onVolcanoesToggled(event.target.checked)} />} label="Volcanoes" labelPlacement="start" />
                                <FormControlLabel className={'switch'} control={<Switch checked={this.props.isFiresVisible} onChange={event => this.props.onFiresToggled(event.target.checked)} />} label="Fires" labelPlacement="start" />
                                <FormControlLabel className={'switch'} control={<Switch checked={this.props.isWildfiresVisible} onChange={event => this.props.onWildfiresToggled(event.target.checked)} />} label="US Wildfires" labelPlacement="start" />
                            </FormGroup>
                        </AccordionDetails>
                    </Accordion>
                </div>
            );
        } else {
            showLayersComponent = (
                <div className={'ShowLayersComponent'} style={{ backgroundColor: cardBackgroundColor }}>
                    <FormGroup>
                        <FormControlLabel
                            className={'switch'}
                            control={<React.Fragment>
                                <Switch
                                    checked={false}
                                    onChange={event => this.setState({ ...this.state, showCriticalEventsUpsell: true })}
                                />
                                <Spacer stretch={true} />
                            </React.Fragment>}
                            label="Critical Events"
                            labelPlacement="start"
                            sx={{ marginLeft: '35px' }}
                        />
                    </FormGroup>
                </div>
            );
        }

        let filteredMaps: MapType[] = [];
        // filter out maps with duplicate regions or missing tilesets
        for (const map of maps) {
            const index = filteredMaps.findIndex(m => m.slug === map.slug);
            if (index === -1) {
                // if this is the first time encountering a map with this slug add it to the list if it has an associated tileset
                if (this.getTilesets(map) !== undefined) {
                    filteredMaps.push(map);
                }
            }
        }

        // opacity slider makes legend taller, currently restricted in production to only Air Force (2684)
        const topLeftContainerTopPosition = isValidUserForFeature(this.props.userData?.id, { 'production': [ClientId.Production.AFB] }) ? 80 : 64;
        let topLeftContainer = (
            <div className={'ImpactMapTopLeftContainer'} style={{ "top": `${topLeftContainerTopPosition}px` }}>
                <MapOptionsComponent
                    mapCategories={mapCategories}
                    maps={filteredMaps}
                    collapsed={this.props.mapOptionsCollapsed}

                    selectedMapCategory={this.selectedMapCategory()}
                    selectedMap={selectedMapType}

                    onMapCategorySelected={category => onMapCategorySelected(category)}
                    onMapSelected={map => onMapSelected(map)}
                    onMapOptionsExpanded={() => this.props.onMapOptionsToggled(false)}
                    onMapOptionsCollapsed={() => this.props.onMapOptionsToggled(true)}
                />

                {showLayersComponent}
                {modelRunComponent}
            </div>
        );

        if (!this.state.processedLocationParams || !this.state.processedTileMapParam || !this.state.processedTileTimeParam) {
            return (
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            );
        }

        return (
            <div className={'ClientImpactPage'}>
                {blurbs.length > 0 && legendComponent}

                {topLeftContainer}

                {locationSelectionComponent}
                {eventDescriptionComponent}

                <div className={'maps'}>
                    {mapElement}
                </div>

                {mapControls}

                {timeSeriesGraphsComponent}

                <Dialog open={this.state.showCriticalEventsUpsell}>
                    <DialogTitle>
                        New Product: Critical Events!
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Live Storm Tracking has been sunset in favor of the Critical Events product. Critical Events help your team monitor natural disasters for improved enterprise resiliency & planning. To learn more and upgrade to this product, contact your account manager.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ ...this.state, showCriticalEventsUpsell: false })}>Got it!</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export const ClientImpactPage = (props: Props) => {
    return <ClientImpactPageInner {...props} />;
};
